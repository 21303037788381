import {
  CloseOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  HourglassOutlined,
  LeftOutlined,
  RightOutlined,
  SyncOutlined,
  UsergroupAddOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  PlusOutlined
} from '@ant-design/icons';
import {
  Avatar,
  Anchor,
  Badge,
  Button,
  Card,
  Calendar,
  Col,
  ConfigProvider as ConfigProvider5,
  DatePicker,
  Divider,
  Drawer,
  Empty,
  Flex,
  FloatButton,
  Form,
  Layout,
  List,
  Popconfirm,
  Row,
  Segmented,
  Select,
  Spin,
  Statistic,
  Skeleton,
  Tag,
  Typography
} from 'antd-v5';
import dayjs from 'dayjs';
import React from 'react';
import Auth from '../../Auth';
import { MobileNav } from './Nav';

const { Content, Sider } = Layout;
const { Text, Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const App = () => {
  const [loading, setLoading] = React.useState(false);
  const [anchorDate, setAnchorDate] = React.useState(dayjs().startOf("day"));
  const [timeRequests, setTimeRequests] = React.useState([]);
  const [timeRecords, setTimeRecords] = React.useState([]);
  const [openSelector, setOpenSelector] = React.useState(false);
  const [selector] = Form.useForm();
  const [mode, setMode] = React.useState("day");
  const [selectDate, setSelectDate] = React.useState(dayjs());
  const listRef = React.useRef(null);

  var weekItems = [
    {
      key: "Sunday",
      href: '#/app/crew-builder#sunday',
      title: "Sunday",
    },
    {
      key: 'Monday',
      href: '#/app/crew-builder#monday',
      title: 'Monday',
    },
    {
      key: 'Tuesday',
      href: '#/app/crew-builder#tuesday',
      title: 'Tuesday',
    },
    {
      key: "Wednesday",
      href: "#/app/crew-builder#wednesday",
      title: "Wednesday",
    },
    {
      key: "Thursday",
      href: "#/app/crew-builder#thursday",
      title: "Thursday",
    },
    {
      key: "Friday",
      href: "#/app/crew-builder#friday",
      title: "Friday",
    },
    {
      key: "Saturday",
      href: "#/app/crew-builder#saturday",
      title: "Saturday",
    }
  ]

  const computeDaysOfWeek = () => {
    const weekDates = [];
    const startOfWeek = dayjs(anchorDate).startOf("week");
    for (var i = 0; i < 7; i++) {
      weekDates.push([startOfWeek.add(i, "day").startOf("day"), startOfWeek.add(i, "day").endOf("day")]);
    }

    return weekDates;
  }

  const fetchTimeRequests = () => {
    setLoading(true);
    let requestAt = dayjs(anchorDate).startOf("week").toISOString();
    let requestEndsAt = dayjs(anchorDate).endOf("week").toISOString();

    if (mode == "calendar") {
      requestAt = dayjs(anchorDate).startOf("month").toISOString();
      requestEndsAt = dayjs(anchorDate).endOf("month").toISOString();
    }

    fetch(`/api/users/${Auth.getCurrentUser()?.id}/production-requests?startsAt=${requestAt}&endsAt=${requestEndsAt}&includeSubmitted=true&includeProduction=true`)
      .then((response) => response.json())
      .then((response) => {
        let filtered = response.filter(p => dayjs(p.requestAt) >= dayjs(anchorDate).startOf("week") && dayjs(p.requestAt) <= dayjs(anchorDate).endOf("week"))
        if (mode == "calendar") filtered = response.filter(p => dayjs(p.requestAt) >= dayjs(anchorDate).startOf("month") && dayjs(p.requestAt) <= dayjs(anchorDate).endOf("month"))
        setTimeRequests(filtered);
        setLoading(false);
      })
  }


  const onWeekSelect = (value) => setAnchorDate(value);
  const openSelectorDrawer = () => setOpenSelector(true);
  const closeSelectorDrawer = () => setOpenSelector(false);

  const onAnchorDateSelect = (value) => {
    setAnchorDate(value);
  }

  const onPrevSelect = (overrideMode = null) => {
    console.log({ overrideMode, mode })
    const prev = dayjs(anchorDate).subtract(1, overrideMode ?? mode);
    setAnchorDate(prev);
  }

  const onNextSelect = (overrideMode = null) => {
    console.log({ overrideMode, mode })

    const next = dayjs(anchorDate).add(1, overrideMode ?? mode);
    setAnchorDate(next);
  }

  const onModeChange = (value) => {
    setMode(value);
  }

  const onSelectorFormSubmit = async () => {
    fetchTimeRequests();
    closeSelectorDrawer();
  }

  React.useEffect(() => {
    fetchTimeRequests()
  }, [anchorDate, mode]);

  const EmptyColor = "#cccccc"
  const LateColor = "#a8071a"
  const SubmittedColor = "#87d068"
  const FutureColor = "#001529"

  const getColor = (requests) => {
    var isEmpty = !requests.length;
    var isLate = requests.filter(p => (!p.productionId || p.productions.length == 0) && dayjs().isAfter(p.requestAt, "day"))?.length > 0;
    var isSubmitted = requests.filter(p => !p.productionId)?.length == 0 && requests.filter(p => p.productions.length > 0)?.length > 0;
    var isFuture = requests.filter(p => (!p.productionId || p.productions.length == 0) && (dayjs().isBefore(p.requestAt, "day") || dayjs().isSame(p.requestAt, "day")))?.length > 0;
    console.log({ requests, isEmpty, isLate, isSubmitted, isFuture })
    console.log({test: requests.filter(p => (!p.productionId))})
    if (isEmpty) return EmptyColor;
    if (isLate) return LateColor;
    if (isSubmitted) return SubmittedColor;
    if (isFuture) return FutureColor;
  }

  const modes = [
    { label: 'Daily', value: 'day', disabled: false },
    { label: 'Weekly', value: 'week', disabled: false },
    { label: 'Calendar', value: 'calendar', disabled: false }
  ];

  const onDateChange = (value, selectInfo) => {
    if (selectInfo.source === 'date') {
      setSelectDate(value);
      listRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }

  const dateCellRender = (value, info, com) => {
    if (info.type == "month") return;

    var matchedTimeRequests = timeRequests?.filter(p => {
      return value.isSame(p.requestAt, "day")
    });

    console.log({ value, info, com })

    let color = getColor(matchedTimeRequests);


    return React.cloneElement(info.originNode, {
      ...info.originNode.props,
      className: `ant5-picker-cell-inner ant5-picker-calendar-date
                  ${selectDate.isSame(value, "date") ? "ant5-picker-calendar-date-today" : ""}
                  ${value.isSame(dayjs(), 'date') ? "ant5-picker-calendar-date-today" : ""}`,
      style: matchedTimeRequests.length > 0 ? { backgroundColor: color, color: "#fff" } : {},
      children: (
        <>
          <div class="ant5-picker-calendar-date-value" style={matchedTimeRequests.length > 0 ? { color: "#fff" } : null}>{value.get("date")}</div>
          <div class="ant5-picker-calendar-date-content" style={{ height: "30px" }}>
          </div>
        </>
      )
    });


  };

  return (
    <ConfigProvider5 prefixCls="ant5">

      <Spin spinning={loading} fullscreen={true} />

      <Content style={{
        marginTop: 16,
        maxWidth: 800,
        width: "100%",
        // backgroundColor: "#001529"
        minHeight: "100vh"
      }}>

        {(anchorDate) &&
          <div style={{
            background: "#001529",
            margin: -16,
            padding: 16,
            marginBottom: 16
          }}>
            <Row gutter={16}>
              <Col span={24}>
                <Text style={{ color: "#fff" }} type="secondary">My Schedule</Text>
                <Title level={4} style={{ marginTop: 8, color: "#fff" }}>{modes.find(p => p.value == mode)?.label}</Title>
              </Col>
            </Row>
            <Segmented
              block
              size="large"
              value={mode}
              options={modes}
              onChange={onModeChange}
            />
            {mode == "day" &&
              <div style={{ marginTop: 24 }}>
                <Segmented
                  block
                  defaultValue={anchorDate.toISOString()}
                  value={anchorDate.toISOString()}
                  onChange={value => {
                    setAnchorDate(dayjs(value))
                  }}
                  options={
                    computeDaysOfWeek()?.map(d => {
                      const matchingTimeRequest = timeRequests?.filter(p => dayjs(p.requestAt).format("YYYY-MM-DD") == d[0].format("YYYY-MM-DD"));
                      let color = getColor(matchingTimeRequest);
                      return {
                        label: (
                          <div>
                            <div>
                              {d[0].format("dd")}
                            </div>
                            <Avatar style={{ backgroundColor: color, marginBottom: 8 }}>{d[0].format('DD')}</Avatar>
                          </div>
                        ),
                        value: d[0].toISOString(),
                      }
                    })
                  }
                />
              </div>
            }
          </div>
        }

        <Layout
          className="site-layout-background"
          style={{
            borderRadius: 8,
            marginTop: 16,

          }}
        >
          <Content
            style={{
              padding: '12px 12px'
            }}
          >
            {
              (anchorDate) && mode != "calendar" &&
              <div>
                <Flex justify="space-between" align="flex-end" style={{ marginBottom: 24 }}>
                  <Flex>
                    {
                      mode == "day" &&
                      <Button type="primary" shape="round" onClick={() => onPrevSelect("week")} icon={<DoubleLeftOutlined />} style={{ marginRight: 4 }}>
                        {/* Prev {mode.charAt(0).toUpperCase() + mode.slice(1)} */}
                      </Button>
                    }
                    <Button type="primary" shape="round" onClick={() => onPrevSelect(null)} icon={<LeftOutlined />} style={{ display: 'flex' }}>
                      {/* Prev {mode.charAt(0).toUpperCase() + mode.slice(1)} */}
                    </Button>
                  </Flex>

                  <Flex>
                    <Button type="primary" shape="round" onClick={() => onNextSelect(null)} icon={<RightOutlined />} iconPosition="end">
                      {/* Next {mode.charAt(0).toUpperCase() + mode.slice(1)} */}
                    </Button>
                    {
                      mode == "day" &&
                      <Button type="primary" shape="round" onClick={() => onNextSelect("week")} icon={<DoubleRightOutlined />} iconPosition="end" style={{ marginLeft: 4 }}>
                        {/* Prev {mode.charAt(0).toUpperCase() + mode.slice(1)} */}
                      </Button>
                    }
                  </Flex>
                </Flex>
                <div style={{ textAlign: "center", marginBottom: 48 }}>
                  <Text type="secondary">{mode.charAt(0).toUpperCase() + mode.slice(1)}</Text>
                  <Title level={4} style={{ marginTop: 8 }}>{
                    anchorDate && mode == "week"
                      ? `${dayjs(anchorDate).startOf(mode).format("MMMM D")} to ${dayjs(anchorDate).endOf(mode).format("MMMM D")}`
                      : dayjs(anchorDate).startOf(mode).format("MMMM D")}
                  </Title>
                </div>
              </div>
            }

            {mode == "calendar" &&
              <Calendar
                fullCellRender={dateCellRender}
                defaultValue={anchorDate}
                value={anchorDate}
                fullScreen={true}
                onSelect={onDateChange}
                onChange={setAnchorDate} />
            }

            {!loading && timeRequests.length >= 0 && weekItems.map(day => {
              if ((mode == "day" || mode == "calendar") && anchorDate && day.title != anchorDate.format("dddd")) return;

              let requests = timeRequests.filter(x => dayjs(x.requestAt).format("dddd") == day.title);

              if (mode == "calendar") requests = timeRequests.filter(x => anchorDate.isSame(x.requestAt, "day"));

              let color = getColor(requests);

              return (
                <Card ref={listRef} style={{ marginBottom: 24, backgroundColor: color == "#cccccc" ? null : color }}>
                  <Flex align="center" >
                    <Flex>
                      <Avatar size="large" style={{ marginRight: 12 }} >{day.title?.substring(0, 3)}</Avatar>
                    </Flex>

                    <List
                      style={{ marginLeft: 12, width: "100%" }}
                      block
                      itemLayout="horizontal"
                      locale={{ emptyText: "No Schedule" }}
                      dataSource={requests}
                      renderItem={(request, index) => (
                        <List.Item
                        >
                          <List.Item.Meta
                            style={{ color: "#fff !important" }}
                            title={<Text style={{ color: "#fff" }}>{request.job.name} - {request.job.description}</Text>}
                            description={
                              <>
                                <Text
                                  style={{ color: "#fff", display: "block", marginBottom: 12 }}
                                  type="secondary">{dayjs(request.requestAt).format("HH:mm a")} {request.requestEndsAt ? "to" : ""} {request.requestEndsAt ? dayjs(request.requestEndsAt).format("HH:mm a") : ""}
                                </Text>
                                <Button
                                  onClick={() => window.location.href = `#/app/time-record-form?productionRequestId=${request.id}&jobId=${request.job.id}&userId=${request.user.id}&producedAt=${encodeURIComponent(request.requestAt)}&producedEndsAt=${encodeURIComponent(request.requestEndsAt)}`}
                                  icon={<PlusOutlined />}
                                  variant="outlined"
                                  ghost
                                >Add Timecard</Button>
                              </>
                            }
                            action
                          />
                          {(request.productions?.length > 0 && request.productionId) &&
                            <Badge color="#fff" style={{ color: "#000" }} count={request.productions.length}>
                              <Avatar shape="square">
                                <Flex wrap justify="center">
                                  {/* traveler.reduce((n, {Amount}) => n + Amount, 0) */}
                                  {
                                    request.productions.length > 0 ?
                                      request.productions?.reduce((n, { quantity }) => n + quantity, 0) :
                                      request.production?.quantity
                                  }h
                                </Flex>
                              </Avatar>
                            </Badge>
                          }
                        </List.Item>
                      )}
                    />

                  </Flex>
                </Card>)
            })}

          </Content>

        </Layout>

        <Drawer
          title="Job Schedule"
          placement="right"
          onClose={closeSelectorDrawer}
          open={openSelector}
        >
          <Form
            form={selector}
            onFinish={onSelectorFormSubmit}
            layout="vertical"
          >
            <Form.Item
              name="week"
              label="Week"
              required
            >
              <DatePicker
                style={{
                  width: "100%"
                }}
                onChange={onWeekSelect}
                picker="week" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">View</Button>
            </Form.Item>
          </Form>
        </Drawer>

        <FloatButton.Group size="large" shape="square" style={{ right: 24, bottom: 120 }}>
          <FloatButton icon={<SyncOutlined />} onClick={openSelectorDrawer} />
          <FloatButton.BackTop visibilityHeight={300} />
        </FloatButton.Group>
      </Content >
      {/* <MobileNav /> */}
    </ConfigProvider5 >
  )
}

export default App;