import { CheckOutlined, ExclamationCircleOutlined, SearchOutlined, DownloadOutlined, FilterOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Input, Modal, Space, Table, Dropdown, Menu, Select, Divider, notification, Tooltip, Popover, Tag, Typography, Result, Switch, List, Card, Form, DatePicker, Badge } from 'antd';
import exportFromJSON from 'export-from-json';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Route, HashRouter, useLocation, withRouter, Redirect, useHistory } from "react-router-dom";
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import axios from 'axios';
import PayrollFormatter from './PayrollFormatter';
import Auth from '../../../Auth';
import dayjs from 'dayjs'

const { confirm } = Modal;
const { Option } = Select;
const { Text } = Typography;

const { RangePicker } = DatePicker;
const EditableContext = React.createContext(null);

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        placement: 'topLeft'
    });
};

export class PayrollReport extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        timeRecords: [],
        error: null,
        workTypes: [],
        company: null,
    };

    componentDidMount() {
        this.fetchTimeRecords();
    }

    fetchTimeRecords = () => {
        const setLoading = this.props.setLoading;

        setLoading(true);

        const query = new URLSearchParams(this.props.location.search);
        let date = moment(query.get('date')).format();

        fetch(`api/jobs/productions/time-records?includeProductionEquipment=true&isForPayroll=true&queryDate=${query.get('date')}&includeProductionRequests=true`)
            .then(response => response.json())
            .then(
                (result) => {

                    fetch('/api/work-types')
                        .then((response) => response.json())
                        .then((workTypesResult) => this.setState({ workTypes: workTypesResult }))

                    fetch(`/api/companies/${Auth.getCurrentUser()?.companyId}`)
                        .then((response) => response.json())
                        .then((companyResult) => {
                            console.log("Company", companyResult)
                            this.setState({ company: companyResult });
                        });

                    let timeRecords = result;
                    console.log("Unfiltered TR", timeRecords)

                    timeRecords = timeRecords.filter(x => x.costCode?.job?.companyId == Auth.getCurrentUser()?.companyId)

                    console.log("Filtered TR", timeRecords)

                    let mappedTimeRecords = timeRecords?.map(x => {
                        return {
                            job: x.costCode?.job?.name,
                            name: x.user?.name,
                            employeeCode: x.user?.employeeCode,
                            costCode: x.costCode?.name,
                            costCodeDescription: x.costCode?.description,
                            isApproved: x.isApproved,
                            producedAt: x.producedAt,
                            workType: x.workType?.name,
                            workClass: x.user?.workClass,
                            hours: x.quantity,
                            key: x.id,
                            id: x.id,
                            description: x.description,
                            productionEquipments: x.productionEquipments,
                            user: x.user,
                            costCodeObj: x.costCode,
                            jobObj: x.costCode.job,
                            exportedAt: x.exportedAt,
                            requestedBy: (x.productionRequests ?? [])[0]?.requestedBy,
                            producedEndsAt: x.producedEndsAt,
                        }
                    });

                    mappedTimeRecords = mappedTimeRecords.map(x => {
                        return {
                            ...x,
                            requestedByName: x.requestedBy?.name ? `${x.requestedBy?.name} (${x.requestedBy?.username})` : ""
                        }
                    })

                    mappedTimeRecords.productionEquipments = mappedTimeRecords.productionEquipments?.map(x => {
                        return {
                            ...x,
                            key: x.id,
                        }
                    });

                    let filteredTimeRecords = mappedTimeRecords.filter(x => moment(x.producedAt).isSame(date, 'day'));
                    console.log("a", filteredTimeRecords)
                    filteredTimeRecords = filteredTimeRecords.filter(x => x.hours > 0);
                    filteredTimeRecords = filteredTimeRecords.sort((a, b) => a.name.localeCompare(b.name))

                    this.setState({
                        timeRecords: filteredTimeRecords
                    });

                    setLoading(false);
                },
                (error) => {
                    this.setState({
                        error
                    });
                    setLoading(false);
                }
            );
    }

    getDateColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <RangePicker
                    style={{ marginRight: "10px" }}
                    format="YYYY-MM-DD"
                    onChange={e => {
                        setSelectedKeys([e])
                    }}
                    value={this.state.searchDate}
                />

                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 100 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => (
            <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            const stringifiedDates = value.map(x => moment(x).format("YYYY-MM-DD"))
            return moment(record[dataIndex]).isBetween(...stringifiedDates, 'days', '[]')
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                //setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                text
            ) : (
                text
            ),
    });

    getTextColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 100 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };


    handleCopy = (record) => {
        console.log(record)
        const url = `#/jobs/time-records/add?jobId=${record.jobObj.id}&costCodeId=${record.costCodeObj.id}&userId=${record.user.id}&hours=${record.hours} &typeOfWork=${record.workClass}&description=${"(Copy) " + record.description}`;
        console.log(url);

        window.location.href = url
        // return <Redirect to={ } />
    }


    handleDelete = record => {
        const self = this;

        Modal.confirm({
            title: 'Are you sure delete this record?',
            icon: <ExclamationCircleOutlined />,
            content: 'Click "Yes" to continue.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                fetch(`api/jobs/productions/${record.key}`, {
                    method: 'DELETE', mode: 'cors', headers: { 'Content-Type': 'application/json' }
                })
                    .then(response => response.json())
                    .then(operation => {
                        if (operation.isSuccessful) {
                            Modal.success({
                                title: 'Success',
                                content: 'Record has been deleted.',
                                icon: <CheckOutlined />,
                                okText: 'Yes',
                            })

                            window.location.reload();
                        }
                    })

            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    handleSave = (row) => {
        const isProductionEquipment = row.isProductionEquipment;

        const newData = isProductionEquipment ? [...this.state.timeRecords.map(x => x.productionEquipments.flat(1))] : [...this.state.timeRecords];
        const index = newData.findIndex((item) => row.key === item.key);

        let item = newData[index];

        newData.splice(index, 1, { ...item, ...row });

        if (isProductionEquipment) {
            const timeRecords = this.state.timeRecords;
            const selectedTimeRecord = timeRecords.find(x => x.id == row.productionId);

            selectedTimeRecord.productionEquipments.forEach((e, i) => {
                if (e.id == row.id) {
                    selectedTimeRecord.productionEquipments[i] = row;
                }
            });
            item = selectedTimeRecord;
            console.log("Selected Time Record", selectedTimeRecord);
        }

        this.props.setLoading(true);

        row.quantity = row.hours
        row.workTypeId = !isNaN(row.workTypeId) ? row.workTypeId : null
        fetch(`api/jobs/productions/${item.key}`,
            {
                method: 'PUT',
                body: JSON.stringify(row),
                mode: 'cors',
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => response.json())
            .then(result => {

                this.setState({
                    timeRecords: newData,
                });

                openNotificationWithIcon('success', 'Success', 'Record has been updated!');
                this.props.setLoading(false);
            })
            .catch(error => {
                openNotificationWithIcon('error', 'Error', 'Something went wrong!');
                this.props.setLoading(false);
            });
    };

    render() {

        const EditableTimeRecordRow = ({ index, ...props }) => {
            const [form] = Form.useForm();
            return (
                <Form form={form} component={false}>
                    <EditableContext.Provider value={form}>
                        <tr {...props} />
                    </EditableContext.Provider>
                </Form>
            );
        };

        const EditableTimeRecordCell = ({
            title,
            editable,
            children,
            dataIndex,
            record,
            handleSave,
            ...restProps
        }) => {
            if (record?.exportedAt) editable = false;

            const [editing, setEditing] = useState(false);
            const inputRef = useRef(null);
            const form = useContext(EditableContext);

            useEffect(() => {
                if (editing) {
                    inputRef.current.focus();
                }
            }, [editing]);

            const toggleEdit = () => {
                setEditing(!editing);
                form.setFieldsValue({
                    [dataIndex]: title == "Date" ? moment(record[dataIndex]) : record[dataIndex],
                });
            };

            const save = async () => {
                try {
                    const values = await form.validateFields();
                    toggleEdit();
                    handleSave({ ...record, ...values });
                } catch (errInfo) {
                    console.log('Save failed:', errInfo);
                }
            };

            let childNode = children;

            const workClasses = [
                {
                    "name": "ADMIN",
                    "description": "Administration & Office Employ"
                },
                {
                    "name": "CARPENTER",
                    "description": "Carpenter"
                },
                {
                    "name": "DRIVER",
                    "description": "Driver"
                },
                {
                    "name": "FLAG",
                    "description": "Flagger"
                },
                {
                    "name": "FOREMAN",
                    "description": "Site Foreman"
                },
                {
                    "name": "GRADE",
                    "description": "Grademan"
                },
                {
                    "name": "INVENTORY",
                    "description": "Inventory Control"
                },
                {
                    "name": "LABOUR",
                    "description": "Site Labour"
                },
                {
                    "name": "MECH",
                    "description": "Mechanics"
                },
                {
                    "name": "OPERATORS",
                    "description": "Equipment Operators"
                },
                {
                    "name": "PIPE",
                    "description": "Pipe Layer"
                },
                {
                    "name": "PM",
                    "description": "Project Manager"
                },
                {
                    "name": "QC",
                    "description": "Quality Control"
                },
                {
                    "name": "SAFETY",
                    "description": "Safety Officer"
                },
                {
                    "name": "SCALE",
                    "description": "Scale Operator"
                },
                {
                    "name": "SUPER",
                    "description": "Superintendant"
                },
                {
                    "name": "Survey",
                    "description": "Surveyor"
                },
                {
                    "name": "WELDER",
                    "description": "Welder"
                }
            ]

            if (editable) {
                childNode = editing ? (
                    <Form.Item
                        style={{
                            margin: 0,
                        }}

                        name={dataIndex}
                        rules={[
                            {
                                required: true,
                                message: `${title} is required.`,
                            },
                        ]}
                    >

                        {
                            (() => {
                                if (title == "Type of Work") {
                                    return (<Select
                                        ref={inputRef}
                                        onChange={save}
                                        showSearch
                                        placeholder="Select Type of Work"
                                        optionFilterProp="children"
                                    >
                                        {this.state.workTypes?.map((c) => <Option value={c.id} key={c.id} > {c.name} </Option>)}
                                    </Select>)
                                }

                                if (title == "Work Class") {
                                    return (<Select
                                        ref={inputRef}
                                        onChange={save}
                                        showSearch
                                        placeholder="Select a Work Class"
                                        optionFilterProp="children"
                                    >
                                        {workClasses?.map((c) => <Option value={c.name} key={c.name} > {c.description} </Option>)}
                                    </Select>)
                                }

                                if (title == "Date") {
                                    return (
                                        <DatePicker ref={inputRef} onBlur={save} onChange={save} />
                                    )
                                }

                                return (
                                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                                )
                            })()
                        }
                    </Form.Item>
                ) : (
                    <div
                        className="editable-cell-value-wrap"
                        style={{
                            paddingRight: 24,

                        }}
                        onClick={toggleEdit}
                    >
                        {children}
                    </div>
                );
            }

            return <td {...restProps}>{childNode}</td>;
        };

        let timeColumns = [

            {
                title: 'Job',
                dataIndex: 'job',
                key: 'job',
                ...this.getTextColumnSearchProps('job'),
                sorter: (a, b) => ('' + a.job).localeCompare(b.job),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                ...this.getTextColumnSearchProps('name'),
                sorter: (a, b) => ('' + a.name).localeCompare(b.name),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Approved',
                dataIndex: 'isApproved',
                key: 'isApproved',
                filters: [
                    {
                        text: 'N/P',
                        value: null,
                    },
                    {
                        text: 'Approved',
                        value: true,
                    },
                    {
                        text: 'Declined',
                        value: false,
                    },
                ],
                onFilter: (value, record) => record.isApproved == value,
                sortDirections: ['descend', 'ascend'],
                render: (p, record) => {
                    const getOptionValue = (option) => {
                        let value = null;
                        if (option == "N/P") value = null;
                        if (option == "Approved") value = true;
                        if (option == "Declined") value = false;
                        return value;
                    }

                    const getOptionName = (value) => {
                        let name = "N/P";
                        if (value == null) name = "N/P";
                        if (value == true) name = "Approved";
                        if (value == false) name = "Declined";
                        return name;
                    }

                    return (
                        <>
                            {!record.exportedAt ? <Badge dot={p == null}>
                                <Select
                                    disabled={record.exportedAt}
                                    placeholder="N/P"
                                    defaultValue={getOptionName(p) != "N/P" ? getOptionName(p) : null}
                                    style={{ width: 120, fontWeight: 500, color: (p ? "#52c41a" : (p == false ? "#ff4d4f" : "#1f1f1f")) }}
                                    onChange={(value) => {
                                        const isApproved = getOptionValue(value);
                                        record.isApproved = isApproved;
                                        this.handleSave(record);
                                    }
                                    }>
                                    <Option value="N/P">N/P</Option>
                                    <Option value="Approved">Yes</Option>
                                    <Option value="Declined">No</Option>
                                </Select>
                            </Badge>
                                :
                                <Text style={{ width: 120, fontWeight: 500, color: (p ? "#52c41a" : (p == false ? "#ff4d4f" : "#1f1f1f")) }}>{
                                    p == true ? "Yes" : "No"
                                }</Text>
                            }

                        </>
                    )
                },
            },
            {
                title: 'Date',
                dataIndex: 'producedAt',
                key: 'producedAt',
                render: producedAt => moment(producedAt).format("YYYY-MM-DD"),
                editable: true
            },
            {
                title: 'Cost Code',
                dataIndex: 'costCode',
                key: 'costCode',
                ...this.getTextColumnSearchProps('costCode'),
                sorter: (a, b) => ('' + a.costCode).localeCompare(b.costCode),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Cost Code Description',
                dataIndex: 'costCodeDescription',
                key: 'costCodeDescription',
                ...this.getTextColumnSearchProps('costCodeDescription'),
            },
            {
                title: 'Type of Work',
                dataIndex: 'workType',
                ...this.getTextColumnSearchProps('workType'),
                key: 'workType',
                editable: true,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Work Class',
                dataIndex: 'workClass',
                ...this.getTextColumnSearchProps('workClass'),
                key: 'workClass',
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Hours',
                dataIndex: 'hours',
                key: 'hours',
                editable: true,
                ...this.getTextColumnSearchProps('hours'),
            },
            {
                title: '',
                dataIndex: 'schedule',
                key: 'schedule',
                render: (value, record) => (record.producedEndsAt && record.producedAt) ? `${dayjs(record.producedAt).format("HH:mm")} to ${dayjs(record.producedEndsAt).format("HH:mm")}` : ""
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                editable: true,
                ...this.getTextColumnSearchProps('description'),
            },
            {
                title: 'Requested By',
                dataIndex: 'requestedByName',
                key: 'requestedByName',
                render: (requestedByName, record) => {
                    return requestedByName
                },
                ...this.getTextColumnSearchProps('requestedByName'),
            },
            {
                title: 'Export Status',
                dataIndex: 'exportedAt',
                key: 'exportedAt',
                filters: [
                    {
                        text: 'Exported',
                        value: true,
                    },
                    {
                        text: 'Pending Export',
                        value: false,
                    }
                ],
                onFilter: (value, record) => {
                    var isExported = record.exportedAt != null;
                    if (isExported) {
                        isExported = !record.exportedAt.includes("0001");
                    }
                    return isExported == value;
                },
                render: (exportedAt, record) => {
                    console.log(record)
                    if (exportedAt?.includes("0001") || !exportedAt) return <Tag color="#595959">Pending Export</Tag>;
                    const reset = () => {
                        record.exportedAt = null;
                        fetch(`api/jobs/productions/${record.key}`,
                            {
                                method: 'PUT',
                                body: JSON.stringify(record),
                                mode: 'cors',
                                headers: { 'Content-Type': 'application/json' }
                            })
                            .then(response => response.json())
                            .then(result => {
                                openNotificationWithIcon('success', 'Success', 'Export reset!');
                                this.props.setLoading(false);
                            })
                            .catch(error => {
                                openNotificationWithIcon('error', 'Error', 'Something went wrong!');
                                this.props.setLoading(false);
                            });
                    }

                    return (
                        <Popover backgroundColor="grey" content={<Button onClick={reset}>Reset</Button>} placement="topLeft" title={`This row has been exported at ${moment(exportedAt).format("YYYY-MM-DD HH:mm:ss")} and will not be included in the exported file.`} arrowPointAtCenter>
                            <Tag color="#87d068">Exported</Tag>
                        </Popover>);

                }
            },
            {
                title: 'Action',
                key: 'operation',
                // fixed: 'right',
                render: (record) => {
                    if (record.exportedAt) return;
                    return (
                        <Space size="small" wrap={true}>

                            <Button disabled={record.exportedAt} size="small" type="primary" danger onClick={() => this.handleDelete(record)}>
                                Delete
                            </Button>
                            {record?.producedEndsAt ? 
                                 <Button size="small" type="primary" onClick={() => window.open(`#/time-records/${record.key}/edit`)}>
                                     Edit (New)
                                 </Button> :
                                 <Button size="small" type="primary" onClick={() => window.open(`#/jobs/time-records/${record.key}`)}>
                                     Edit
                                 </Button>
                             }
                        </Space >

                    )
                },
            },
        ];

        let equipmentColumns = [
            {
                title: 'Equipment',
                dataIndex: 'name',
                key: 'name',
                ...this.getTextColumnSearchProps('name'),
            },
            {
                title: 'Hours Used',
                dataIndex: 'hoursUsed',
                key: 'hoursUsed',
                editable: true,
            },
            {
                title: 'Liters of Fuel',
                dataIndex: 'litersOfFuel',
                key: 'litersOfFuel',
                editable: true,
            },
            {
                title: 'Notes',
                dataIndex: 'notes',
                key: 'notes',
                editable: true,
            }
        ]

        equipmentColumns = equipmentColumns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });

        const timeRecordComponent = {
            body: {
                row: EditableTimeRecordRow,
                cell: EditableTimeRecordCell,
            },
        };

        timeColumns = timeColumns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });

        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Reports</Breadcrumb.Item>
                    <Breadcrumb.Item>Payroll Report</Breadcrumb.Item>
                </Breadcrumb>

                {this.state.timeRecords?.length ?
                    <Card title="Payroll Report">
                        <Table
                            components={timeRecordComponent}
                            rowClassName={(record, index) => {
                                return record.exportedAt ? 'disabled-row' : 'editable-row'
                            }}
                            rowKey={record => `time-record-${record.id}`}
                            columns={timeColumns}
                            expandable={{
                                expandedRowRender: record => {

                                    return (<>
                                        <Table
                                            components={timeRecordComponent}
                                            rowClassName={() => 'editable-row'}
                                            rowKey={record => `equipment - ${record.id} `}
                                            dataSource={record.productionEquipments?.map(x => { return { key: x.id, exportedAt: record.exportedAt, name: x.equipment.name, ...x, isProductionEquipment: true } })}
                                            columns={equipmentColumns}>
                                        </Table>
                                    </>)
                                }

                                ,
                                rowExpandable: record => record.productionEquipments?.length > 0
                            }}
                            dataSource={this.state.timeRecords}
                            scroll={{ x: 1300 }}
                            pagination={false}
                            footer={currentPageData => {
                                var self = this;
                                const setLoading = self.props.setLoading;
                                let data = this.state.timeRecords;

                                async function handleMenuClick(e) {

                                    if (e.key == "equipment" || e.key == "equipment-bio-timbro") {
                                        data = self.state.timeRecords.filter(x => {
                                            return x.isApproved
                                        })
                                    }
                                    else {
                                        data = self.state.timeRecords.filter(x => {
                                            const exportedAt = x.exportedAt;
                                            const isPendingExport = exportedAt?.includes("0001-01-01") || !exportedAt;
                                            return isPendingExport && x.isApproved
                                        })
                                    }

                                    setLoading(true)
                                    self.fetchTimeRecords()

                                    const updateProductionDownloadHistory = async (id) => {
                                        console.log("Updated Export", id)
                                        return await axios.put(`api/jobs/productions/${id}`, {
                                            exportedAt: moment.utc()
                                        });
                                    }

                                    const updateProductionsSync = async () => {
                                        for (const x of data) {
                                            try {
                                                const updated = await updateProductionDownloadHistory(x.id);
                                            }
                                            catch {
                                                data = data.filter(y => y.id != x.id)
                                            }
                                        }
                                    };

                                    if (e.key != "equipment" && e.key != "equipment-bio-timbro") {
                                        console.log("Updating Productions Exported")
                                        await updateProductionsSync();
                                    }

                                    const updatedData = []

                                    const query = new URLSearchParams(self.props.location.search);
                                    const currentDate = moment(query.get('date')).format("YYMMDD")
                                    console.log("Data", data)

                                    let key = PayrollFormatter.getHeader("Admin", currentDate)

                                    if (Auth.getCurrentUser().company?.includes("Bio")) {
                                        key = PayrollFormatter.getHeader("00-Offset", currentDate)
                                    }

                                    if (Auth.getCurrentUser().company?.includes("Express")) {
                                        key = PayrollFormatter.getHeader("ERS", currentDate)
                                    }

                                    if (self.state.company.payrollHeader) {
                                        var header = self.state.company.payrollHeader
                                        key = PayrollFormatter.getHeader(header, currentDate)
                                        console.log("Header", header)
                                    }

                                    if (e.key != "equipment" && e.key != "equipment-bio-timbro") {

                                        key = PayrollFormatter.getHeader("Admin", currentDate, true)
                                        if (Auth.getCurrentUser().company?.includes("Bio")) {
                                            key = PayrollFormatter.getHeader("00-Offset", currentDate, true)
                                        }
                                        if (Auth.getCurrentUser().company?.includes("Express")) {
                                            const keyJob = data[0]?.job;
                                            key = PayrollFormatter.getHeader("ERS", currentDate, true)
                                        }
                                        if (self.state.company.payrollHeader) {
                                            var header = self.state.company.payrollHeader
                                            key = PayrollFormatter.getHeader(header, currentDate)
                                            console.log("Header", header)
                                        }

                                        data.forEach(x => {
                                            let y = {};
                                            let value = PayrollFormatter.getTimecard(x)
                                            y[key] = value
                                            updatedData.push(y)
                                        })
                                        const footer = {}
                                        footer[key] = PayrollFormatter.getFooter()
                                        updatedData.push(footer)
                                    }
                                    else {
                                        const productions = data.map(x => x.productionEquipments).flat(1)
                                        productions.forEach(x => {
                                            const p = data.find(y => x.productionId == y.id)
                                            x.production = p
                                            x.job = p.job
                                        })

                                        let key = PayrollFormatter.getHeader("Admin", currentDate)

                                        if (Auth.getCurrentUser().company?.includes("Bio")) {
                                            const keyJob = productions[0]?.job;
                                            key = PayrollFormatter.getHeader(keyJob ? keyJob : "001", currentDate)

                                            if (e.key == "equipment-bio-timbro") key = PayrollFormatter.getHeader("Admin", currentDate)
                                        }
                                        if (Auth.getCurrentUser().company?.includes("Express")) {
                                            const keyJob = productions[0]?.job;
                                            key = PayrollFormatter.getHeader("ERS", currentDate)
                                        }

                                        if (self.state.company.payrollHeader) {
                                            var header = self.state.company.payrollHeader
                                            key = PayrollFormatter.getHeader(header, currentDate)
                                            console.log("Header", header)
                                        }

                                        productions.forEach(x => {
                                            let y = {};
                                            if (Auth.getCurrentUser().company?.includes("Bio")) {
                                                if (e.key == "equipment") { // BIO
                                                    console.log("equipment-debug", x.equipment?.isRental)
                                                    if (x.equipment?.isRental) return;
                                                }

                                                if (e.key == "equipment-bio-timbro") { // TIMBRo
                                                    console.log("isRental", x.equipment?.isRental)
                                                    if (!x.equipment?.isRental) return;
                                                }
                                            }

                                            if (Auth.getCurrentUser().company?.includes("Timbro")) {
                                                if (e.key == "equipment") { // BIO
                                                    console.log("equipment-debug", x.equipment?.isRental)
                                                    if (x.equipment?.isRental) return;
                                                }
                                            }

                                            let value = PayrollFormatter.getEquipment(x, Auth.getCurrentUser().company?.includes("Bio"))
                                            y[key] = value
                                            updatedData.push(y)
                                        })
                                        const footer = {}
                                        footer[key] = PayrollFormatter.getFooter()
                                        updatedData.push(footer)
                                        console.log(footer)
                                    }


                                    console.log("Updated Data", updatedData)

                                    data = updatedData

                                    console.log("Final Data", data)

                                    let fileType = exportFromJSON.types.txt;
                                    let fileName = "payroll";
                                    switch (e.key) {
                                        case "csv":
                                            fileType = exportFromJSON.types.csv;
                                            break;
                                        case "xls":
                                            fileType = exportFromJSON.types.xls;
                                            break;
                                        case "json":
                                            fileType = exportFromJSON.types.json;
                                            break;
                                        case "txt":
                                            fileType = exportFromJSON.types.txt;
                                            break;
                                        case "filtered-csv":
                                            fileType = exportFromJSON.types.csv;
                                            break;
                                        case "filtered-xls":
                                            fileType = exportFromJSON.types.xls;
                                            break;
                                        case "filtered-json":
                                            fileType = exportFromJSON.types.json;
                                            break;
                                        case "filtered-txt":
                                            fileType = exportFromJSON.types.txt;
                                            break;
                                        case "special-csv":
                                            fileType = exportFromJSON.types.csv;
                                            break;
                                        case "special-xls":
                                            fileType = exportFromJSON.types.xls;
                                            break;
                                        case "special-json":
                                            fileType = exportFromJSON.types.json;
                                            break;
                                        case "special-txt":
                                            fileType = exportFromJSON.types.txt;
                                            break;
                                        case "employee":
                                            fileType = exportFromJSON.types.csv;
                                            data = data;
                                            break;
                                        case "shop":
                                            fileType = exportFromJSON.types.csv;
                                            break;
                                        case "equipment":
                                            fileType = exportFromJSON.types.csv;
                                            fileName = "equipment"

                                            break;
                                        case "equipment-bio-timbro":
                                            fileType = exportFromJSON.types.csv;
                                            fileName = "equipment"
                                            break;
                                    }

                                    const companyName = Auth.getCurrentUser().company?.toLowerCase().replace(" ", "_");
                                    fileName = `${companyName}_${fileName}_${moment(query.get('date')).format("YYYYMMDD")}_${moment().format("YYYYMMDDHHmmss")}`
                                    const final = data.map(({ job, key, ...items }) => items);
                                    console.log("FINAL", final)
                                    const final2 = JSON.stringify([
                                        {
                                            "H                                           BWAdmin    220531000000HY      ": "Z"
                                        }
                                    ]);
                                    const final3 = JSON.parse(final2)
                                    console.log("EFJ", exportFromJSON)
                                    // exportFromJSON({
                                    //     data: final3,
                                    //     fileName: "final3",
                                    //     fields: [],
                                    //     exportType: "csv",
                                    //     // delimiter: ","
                                    //     withBOM: true
                                    // });

                                    const fields = []
                                    const exportType = "csv"

                                    exportFromJSON({ data, fileName, fields, exportType })


                                    // var csvGenerator = new arrayToCsv(data, { delimiter: "," });
                                    // csvGenerator.saveFile(fileName)

                                    self.fetchTimeRecords()
                                    setLoading(false)
                                }

                                const menu = (
                                    <Menu onClick={handleMenuClick}>
                                        <Menu.Item key="csv">Export as CSV</Menu.Item>
                                        <Menu.Item key="xls">Export as XLS</Menu.Item>
                                        <Menu.Item key="json">Export as JSON</Menu.Item>
                                    </Menu>
                                );

                                const filteredMenu = (
                                    <Menu onClick={handleMenuClick}>
                                        <Menu.Item key="filtered-csv">Export as CSV</Menu.Item>
                                        <Menu.Item key="filtered-xls">Export as XLS</Menu.Item>
                                        <Menu.Item key="filtered-json">Export as JSON</Menu.Item>
                                    </Menu>
                                );

                                const specialMenu = (
                                    <Menu onClick={handleMenuClick}>
                                        <Menu.Item key="special-csv">
                                            Export <strong>Employee</strong> Records
                                        </Menu.Item>
                                        {/* <Menu.Item key="shop">Export <strong>Shop</strong> Records</Menu.Item> */}
                                        <Menu.Item key="equipment">
                                            Export <strong>Equipment</strong> Records
                                            {Auth.getCurrentUser().company?.includes("Bio") && <> - Bio</>}
                                        </Menu.Item>

                                        {Auth.getCurrentUser().company?.includes("Bio") &&
                                            <Menu.Item key="equipment-bio-timbro">
                                                Export <strong>Equipment</strong> Records - Timbro
                                            </Menu.Item>}
                                    </Menu>
                                );

                                return (
                                    <Space size="small">

                                        <Dropdown overlay={specialMenu}>
                                            <Button type="primary" ghost={true}>Download Time Cards</Button>
                                        </Dropdown>
                                    </Space>
                                );

                            }}
                        />
                    </Card>
                    :
                    <Card>

                        <Result
                            title="No Records Found"
                            extra={
                                <Button type="primary" onClick={() => window.history.go(-1)}>
                                    Go Back
                                </Button>
                            }
                            style={{ opacity: this.state.job?.name && this.state.timeRecords?.length > 0 ? 100 : 100 }}
                        />
                    </Card>
                }
            </div>
        );
    }
}