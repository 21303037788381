const getWindow = () => document.querySelector("html");

export const disableWindowScroll = () => {
  const window = getWindow();
  window.style.overflowY = "hidden";
}

export const enableWindowScroll = () => {
  const window = getWindow();
  window.style.removeProperty("overflow-y");
}

export const toggleWindowScroll = (isOpen) => {
  isOpen ? disableWindowScroll() : enableWindowScroll();
}