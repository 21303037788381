import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  ClockCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  HourglassOutlined,
  LeftOutlined,
  RightOutlined,
  SyncOutlined,
  UsergroupAddOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  MoonOutlined
} from '@ant-design/icons';

import {
  Alert,
  Avatar,
  Button,
  Collapse,
  Col,
  ConfigProvider as ConfigProvider5,
  DatePicker,
  Divider,
  Drawer,
  Empty,
  FloatButton,
  Flex,
  Form,
  Input,
  Layout,
  List,
  Modal,
  Popconfirm,
  Row,
  Segmented,
  Select,
  Skeleton,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd-v5';
import dayjs from 'dayjs';
import React from 'react';
import Auth from '../../Auth';
import { MobileNav } from './Nav';

const { Content, Sider, Fxooter } = Layout;
const { Text, Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const App = () => {
  const [loading, setLoading] = React.useState(false);
  const [openCrewBuilder, setOpenCrewBuilder] = React.useState(false);
  const [openSelector, setOpenSelector] = React.useState(false);
  const [form] = Form.useForm();
  const [selector] = Form.useForm();

  const [anchorDate, setAnchorDate] = React.useState();
  const [jobId, setJobId] = React.useState();
  const [timeRequests, setTimeRequests] = React.useState([]);
  const [jobs, setJobs] = React.useState([]);
  const [mode, setMode] = React.useState("day");
  const [dateRange, setDateRange] = React.useState([]);
  const [requestTimeStart, setRequestTimeStart] = React.useState();
  const [requestTimeEnd, setRequestTimeEnd] = React.useState();
  const [crew, setCrew] = React.useState([]);

  const [isOvernightWork, setIsOvernightWork] = React.useState(false);
  const [computedHours, setComputedHours] = React.useState();

  const [selectedProductionRequest, setSelectedProductionRequest] = React.useState();
  const [editScheduleForm] = Form.useForm();
  const [openEditSchedule, setOpenEditSchedule] = React.useState(false);

  const [modalOpen, setModalOpen] = React.useState(false);

  const getCurrentJob = (jobId) => jobs.find(j => j.id == jobId);

  var weekItems = [
    {
      key: "Sunday",
      href: '#/app/crew-builder#sunday',
      title: "Sunday",
    },
    {
      key: 'Monday',
      href: '#/app/crew-builder#monday',
      title: 'Monday',
    },
    {
      key: 'Tuesday',
      href: '#/app/crew-builder#tuesday',
      title: 'Tuesday',
    },
    {
      key: "Wednesday",
      href: "#/app/crew-builder#wednesday",
      title: "Wednesday",
    },
    {
      key: "Thursday",
      href: "#/app/crew-builder#thursday",
      title: "Thursday",
    },
    {
      key: "Friday",
      href: "#/app/crew-builder#friday",
      title: "Friday",
    },
    {
      key: "Saturday",
      href: "#/app/crew-builder#saturday",
      title: "Saturday",
    }
  ]

  const computeDaysOfWeek = () => {
    const weekDates = [];
    const startOfWeek = dayjs(anchorDate).startOf("week");
    for (var i = 0; i < 7; i++) {
      weekDates.push([startOfWeek.add(i, "day").startOf("day"), startOfWeek.add(i, "day").endOf("day")]);
    }

    return weekDates;
  }

  const computeHours = () => {
    const values = form.getFieldsValue();
    console.log({ values })
    if (values.timeStart && values.timeEnd) {
      const start = dayjs(`${values.dateStart}T${values.timeStart}`);
      let end = dayjs(`${values.dateStart}T${values.timeEnd}`);

      if (start.isAfter(end)) {
        end = end.add(1, "day")
        setIsOvernightWork(true)
      }
      else {
        setIsOvernightWork(false)
      }


      setComputedHours(end.diff(start, "hours", true).toFixed(2));

      console.log("Compute")
    }
  }

  const fetchTimeRequests = () => {
    if (!jobId) return;

    setLoading(true);

    const requestAt = dayjs(anchorDate).startOf("week").toISOString();
    const requestEndsAt = dayjs(anchorDate).endOf("week").toISOString();

    fetch(`/api/jobs/${jobId}/production-requests?requestAt=${requestAt}&requestEndsAt=${requestEndsAt}`)
      .then((response) => response.json())
      .then((response) => {
        setTimeRequests(response);
        setLoading(false);
      })
  }

  const fetchJobs = async () => {
    setLoading(true);
    await fetch(`/api/jobs?includeAssignedUsers=true`)
      .then((response) => response.json())
      .then((jobResult) => {
        setJobs(jobResult);
        setLoading(false);
      })
  }

  const onJobSelect = (item) => {
    setJobId(item);

    form.setFieldValue("job", item)
    selector.setFieldValue("job", item)
  }

  const deleteTimeRequests = (productionRequestId) => {
    setLoading(true);
    return new Promise((resolve) => {
      fetch(`api/jobs/production-requests/${productionRequestId}`, {
        method: 'DELETE', mode: 'cors', headers: { 'Content-Type': 'application/json' }
      })
        .then(response => response.json())
        .then(() => {
          resolve(null);
          setLoading(false);
        })
        .then(() => {
          fetchTimeRequests();
        })
    });
  }

  const onAnchorDateSelect = (value) => {
    setAnchorDate(value);
  }

  const onPrevSelect = (overrideMode = null) => {
    console.log({ overrideMode, mode })
    const prev = dayjs(anchorDate).subtract(1, overrideMode ?? mode);
    setAnchorDate(prev);
  }

  const onNextSelect = (overrideMode = null) => {
    console.log({ overrideMode, mode })

    const next = dayjs(anchorDate).add(1, overrideMode ?? mode);
    setAnchorDate(next);
  }

  const openSelectorDrawer = () => setOpenSelector(true);
  const closeSelectorDrawer = () => setOpenSelector(false);
  const openCrewBuilderDrawer = () => setOpenCrewBuilder(true);
  const closeCrewBuilderDrawer = () => {
    form.resetFields();
    setOpenCrewBuilder(false);
    form.setFieldValue("job", jobId)
  }

  const openEditScheduleDrawer = (request) => {
    editScheduleForm.resetFields()
    setSelectedProductionRequest(request)

    console.log({ request })

    editScheduleForm.setFieldValue("dateStart", dayjs(request?.requestAt).format("YYYY-MM-DD"))
    editScheduleForm.setFieldValue("timeStart", dayjs(request?.requestAt).format("HH:mm"))
    editScheduleForm.setFieldValue("timeEnd", dayjs(request?.requestEndsAt).format("HH:mm"))

    setOpenEditSchedule(true);
  }
  const closeEditScheduleDrawer = () => {
    editScheduleForm.resetFields()
    setSelectedProductionRequest(null)
    setOpenEditSchedule(false);
  }

  const onSelectorFormSubmit = async () => {
    fetchTimeRequests();
    closeSelectorDrawer();
  }

  const onFieldsChange = (values) => {
    const dateStart = form.getFieldValue("dateStart");
    const dateEnd = form.getFieldValue("dateEnd");
    if (dateStart && dateEnd) {
      const dates = generateDateRange(dateStart, dateEnd);
      setDateRange(dates);
    }
  }

  const generateDateRange = (dateStart, dateEnd) => {
    const dDateStart = dayjs(dateStart);
    const dDateEnd = dayjs(dateEnd);
    let start = dDateStart;

    let datesList = [dateStart];

    while (start.isBefore(dDateEnd)) {
      start = start.add(1, "day");
      datesList.push(start.format(`YYYY-MM-DD`));
    }
    return datesList;
  }

  const setupCrew = async () => {
    const values = form.getFieldsValue();
    const { users, job } = values;

    const requestedById = Auth.getCurrentUser()?.id;

    setModalOpen(true)

    setLoading(true)
    for await (const date of dateRange) {
      for await (const user of users) {
        console.log({ date })
        const start = dayjs(`${date}T${values.timeStart}`)
        let end = dayjs(`${date}T${values.timeEnd}`)

        if (start.isAfter(end)) {
          end = end.add(1, "day")
          setIsOvernightWork(true)
        }

        const match = {
          userId: user,
          requestAt: start,
          requestEndsAt: end,
          jobId: job,
        }
        
        await fetch(`api/jobs/production-requests/_match`, {
          method: 'POST', body: JSON.stringify(match), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        }).then(response => {
          if (response.status == 204) return null
          return response?.json()

        })
          .then(productionRequest => {
            console.log({

            })
            setCrew(crew => [...crew, {
              userId: user,
              requestAt: start,
              requestEndsAt: end,
              requestedById,
              jobId: job,
              isValid: !productionRequest,
              name: getCurrentJob(jobId)?.userAssignments?.find(p => p.user.id == user).user?.name
            }]);

          });
      }
    }

    setLoading(false)
  }

  const onCrewBuilderFormSubmit = async () => {
    setLoading(true)

    async function processRequest(request) {
      await fetch(`api/jobs/${jobId}/production-requests`, {
        method: 'POST', body: JSON.stringify(request), mode: 'cors', headers: { 'Content-Type': 'application/json' }
      }).then(response => response.json());
    }

    async function processPromisesBatch(
      items,
      limit,
      fn,
    ) {
      let results = [];
      for (let start = 0; start < items.length; start += limit) {
        const end = start + limit > items.length ? items.length : start + limit;

        const slicedResults = await Promise.all(items.slice(start, end).map(fn));

        results = [
          ...results,
          ...slicedResults,
        ]
      }

      return results;
    }

    await processPromisesBatch(crew.filter(p => p.isValid), 3, processRequest)

    setLoading(false);
    setDateRange([])
    form.resetFields();
    closeCrewBuilderDrawer();
    fetchTimeRequests();
    setCrew([])
    setModalOpen(false);


    Modal.success({
      content: 'Requests has been submitted.',
    });

  }

  const onModeChange = (value) => {
    setMode(value);
  }

  const onEditScheduleFormSubmit = async (values) => {
    setLoading(true);

    var payload = {
      requestAt: dayjs(`${values.dateStart}T${values.timeStart}`),
      requestEndsAt: dayjs(`${values.dateStart}T${values.timeEnd}`),
    }
    await fetch(`api/jobs/production-requests/${selectedProductionRequest?.id}`, {
      method: 'PATCH', body: JSON.stringify(payload), mode: 'cors', headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      setLoading(false)
      fetchTimeRequests()
      closeEditScheduleDrawer()
      if (response.status == 204) return null
      return response?.json()
    })

  }

  const copyRecord = (record) => {
    form.setFieldValue("dateStart", dayjs(record.requestAt).format("YYYY-MM-DD"))
    form.setFieldValue("dateEnd", dayjs(record.requestEndsAt).format("YYYY-MM-DD"))
    form.setFieldValue("timeStart", dayjs(record.requestAt).format("HH:mm:ss"))
    form.setFieldValue("timeEnd", dayjs(record.requestEndsAt).format("HH:mm:ss"))
    form.setFieldValue("users", [record.user.id])
    openCrewBuilderDrawer()
  }

  const EmptyColor = "#cccccc"
  const LateColor = "#a8071a"
  const SubmittedColor = "#87d068"
  const FutureColor = "#001529"

  const getColor = (requests) => {
    var isEmpty = !requests.length;
    var isLate = requests.filter(p => !p.productionId && dayjs().isAfter(p.requestAt, "day"))?.length > 0;
    var isSubmitted = requests.filter(p => !p.productionId)?.length == 0;
    var isFuture = requests.filter(p => !p.productionId && (dayjs().isBefore(p.requestAt, "day") || dayjs().isSame(p.requestAt, "day")))?.length > 0;
    console.log({ requests, isEmpty, isLate, isSubmitted, isFuture })

    if (isEmpty) return EmptyColor;
    if (isLate) return LateColor;
    if (isSubmitted) return SubmittedColor;
    if (isFuture) return FutureColor;
  }

  React.useEffect(() => {
    fetchTimeRequests()
    fetchJobs()
    if (!anchorDate || !jobId) openSelectorDrawer()

  }, [anchorDate, jobId, mode]);

  return (
    <ConfigProvider5 prefixCls="ant5">

      <Spin spinning={loading} fullscreen={true} />

      <Content style={{
        marginTop: 16,
        maxWidth: 800,
        width: "100%",
        minHeight: "100vh"
      }}>
        {
          (jobId && anchorDate) &&
          <div style={{
            background: "#001529",
            margin: -16,
            padding: 16,
            marginBottom: 16
          }}>
            <Row gutter={16}>
              <Col span={12}>
                <Text style={{ color: "#fff" }} type="secondary">Job</Text>
                <Title level={4} style={{ marginTop: 8, color: "#fff" }}>{jobId ? `${getCurrentJob(jobId)?.name} - ${getCurrentJob(jobId)?.description}` : ""}</Title>
              </Col>
            </Row>
            <Segmented
              block

              size="large"
              value={mode}
              options={[
                { label: 'Daily', value: 'day', disabled: false },
                { label: 'Weekly', value: 'week', disabled: false },
              ]}
              onChange={onModeChange}
            />
            {mode == "day" &&
              <div style={{ marginTop: 24 }}>
                <Segmented
                  block
                  defaultValue={anchorDate.toISOString()}
                  value={anchorDate.toISOString()}
                  onChange={value => {
                    setAnchorDate(dayjs(value))
                  }}
                  options={
                    computeDaysOfWeek()?.map(d => {
                      const matchingTimeRequest = timeRequests?.filter(p => dayjs(p.requestAt).format("YYYY-MM-DD") == d[0].format("YYYY-MM-DD"));
                      let color = getColor(matchingTimeRequest);
                      return {
                        label: (
                          <div>
                            <div>
                              {d[0].format("dd")}
                            </div>
                            <Avatar style={{ backgroundColor: color, marginBottom: 8 }}>{d[0].format('DD')}</Avatar>
                          </div>
                        ),
                        value: d[0].toISOString(),
                      }
                    })
                  }
                />
              </div>
            }
          </div>
        }
        <Layout
          className="site-layout-background"
          style={{
            borderRadius: 8,
            marginTop: 16,
          }}

        >
          <Content
            style={{
              padding: '12px 12px',
            }}
          >
            {
              (jobId && anchorDate) &&
              <div>
                <Flex justify="space-between" align="flex-end" style={{ marginBottom: 24 }}>
                  <Flex>
                    {
                      mode == "day" &&
                      <Button type="primary" shape="round" onClick={() => onPrevSelect("week")} icon={<DoubleLeftOutlined />} style={{ marginRight: 4 }}>
                        {/* Prev {mode.charAt(0).toUpperCase() + mode.slice(1)} */}
                      </Button>
                    }
                    <Button type="primary" shape="round" onClick={() => onPrevSelect(null)} icon={<LeftOutlined />} style={{ display: 'flex' }}>
                      {/* Prev {mode.charAt(0).toUpperCase() + mode.slice(1)} */}
                    </Button>
                  </Flex>

                  <Flex>
                    <Button type="primary" shape="round" onClick={() => onNextSelect(null)} icon={<RightOutlined />} iconPosition="end">
                      {/* Next {mode.charAt(0).toUpperCase() + mode.slice(1)} */}
                    </Button>
                    {
                      mode == "day" &&
                      <Button type="primary" shape="round" onClick={() => onNextSelect("week")} icon={<DoubleRightOutlined />} iconPosition="end" style={{ marginLeft: 4 }}>
                        {/* Prev {mode.charAt(0).toUpperCase() + mode.slice(1)} */}
                      </Button>
                    }
                  </Flex>
                </Flex>
                <div style={{ textAlign: "center", marginBottom: 48 }}>
                  <Text type="secondary">{mode.charAt(0).toUpperCase() + mode.slice(1)}</Text>
                  <Title level={4} style={{ marginTop: 8 }}>{
                    anchorDate && mode == "week"
                      ? `${dayjs(anchorDate).startOf(mode).format("MMMM D")} to ${dayjs(anchorDate).endOf(mode).format("MMMM D")}`
                      : dayjs(anchorDate).startOf(mode).format("MMMM D")}
                  </Title>
                </div>
              </div>
            }

            {(!loading && !timeRequests.length && jobId) &&
              <Empty
                description={"No Crew Schedule Found"}
                style={{
                  marginBottom: 24
                }}>
                <Button type="primary" onClick={openCrewBuilderDrawer}>Add Crew Members</Button>
              </Empty>
            }

            {(!loading && !timeRequests.length && !jobId) &&
              <Empty description={<Button type="primary" onClick={openSelectorDrawer}>Select Job & Week</Button>}>
              </Empty>
            }

            {!loading && timeRequests.length > 0 && weekItems.map(dayOfWeek => {
              if (mode == "day" && anchorDate && dayOfWeek.title != anchorDate.format("dddd")) return;
              return (
                <>
                  <Divider>
                    {dayOfWeek.title}
                  </Divider>

                  <List
                    loading={loading}
                    itemLayout="horizontal"
                    dataSource={timeRequests.filter(x => dayjs(x.requestAt).format("dddd") == dayOfWeek.title)}
                    renderItem={(request) => {
                      const scheduleText = `${dayjs(request.requestAt).format("hh:mm a")} ${request.requestEndsAt ? "to" : ""} ${request.requestEndsAt ? dayjs(request.requestEndsAt).format("hh:mm a") : ""}`
                      return (
                        <List.Item
                          actions={[
                            <Popconfirm
                              title="Delete Time Request"
                              description="Are you sure to delete this time request?"
                              onConfirm={() => deleteTimeRequests(request.id)}
                              onCancel={() => console.log("Cancel")}
                              okText="Yes"
                              cancelText="No"
                              placement="bottom"
                            >
                              <Button shape="circle" style={{ display: request.productionId ? "none" : "" }} danger icon={<DeleteOutlined />} ></Button>
                            </Popconfirm>,
                            <Button shape="circle" onClick={() => copyRecord(request)} icon={<CopyOutlined />} ></Button>,
                            <Button
                              shape="circle"
                              icon={<EditOutlined />}
                              onClick={() => openEditScheduleDrawer(request)}
                            >
                            </Button>
                          ]
                          }
                        >
                          <Skeleton avatar title={false} loading={loading} active>
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  shape="square"
                                  icon={request.productionId ? <CheckOutlined /> : <ClockCircleOutlined />}
                                  style={request.productionId ? {
                                    backgroundColor: "#87d068"
                                  } : {}}
                                  onClick={() => window.location.href = `#/app/time-record-form?productionRequestId=${request.id}&jobId=${request.job.id}&userId=${request.user.id}&producedAt=${encodeURIComponent(request.requestAt)}&producedEndsAt=${encodeURIComponent(request.requestEndsAt)}`}
                                />
                              }
                              title={request.user.name}
                              description={
                                scheduleText
                              }
                            />
                          </Skeleton>
                        </List.Item>
                      )
                    }}
                  />

                </>
              )
            })}

          </Content>

        </Layout>

        <Drawer
          title="Build Crew"
          placement="right"
          onClose={closeCrewBuilderDrawer}
          open={openCrewBuilder}
        >
          <Form
            form={form}
            onFinish={setupCrew}
            layout="vertical"
            onFieldsChange={onFieldsChange}
          >
            <Form.Item
              name="job"
              label="Job"
              required
            >
              <Select
                style={{
                  width: "100%"
                }}
                onChange={onJobSelect}
                showSearch
                placeholder="Select a Job"
                optionFilterProp="children"
              >
                {jobs?.map((job) => <Option value={job.id.toString()} key={job.id} > {job.name} - {job.description} </Option>)}
              </Select>
            </Form.Item>

            <Form.Item
              name="requestDates"
              label="Dates"
              onChange={computeHours}
              required
            >
              <Space style={{ display: 'flex' }} align="baseline">
                <Form.Item
                  name="dateStart"
                  style={{ marginBottom: 12 }}
                  rules={[
                    {
                      required: true,
                      message: 'Please select start range!',
                    },
                  ]}
                >
                  <Input
                    type="date"
                    onChange={(e) => {
                      const dateStartValue = e.target.value;
                      const dateEndValue = form.getFieldValue("dateEnd");

                      if (!dateEndValue) form.setFieldValue("dateEnd", e.target.value);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="dateEnd"
                  style={{ marginBottom: 12 }}
                  rules={[
                    {
                      required: true,
                      message: 'Please select end range!',
                    },
                  ]}
                >
                  <Input
                    type="date"
                  />
                </Form.Item>
              </Space>
            </Form.Item>

            {
              isOvernightWork && <Alert
                style={{ marginBottom: 12 }}
                message="Overnight Work"
                description="Your recorded time start and time end is considered overnight work."
                type="info"
                icon={<MoonOutlined />}
                showIcon />
            }

            <Form.Item
              name="requestTimes"
              label="Shift Time"
              required
              style={{ marginBottom: 0 }}
            >
              <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item
                  name="timeStart"
                  onChange={computeHours}
                >
                  <Input
                    type="time"
                    required
                    onChange={(e) => setRequestTimeStart(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  name="timeEnd"
                  onChange={computeHours}
                  rules={[
                    {
                      required: true,
                      message: 'Please select end range!',
                    },
                  ]}
                >
                  <Input
                    type="time"
                    required
                    onChange={(e) => setRequestTimeEnd(e.target.value)}
                  />
                </Form.Item>
              </Space>
            </Form.Item>



            {
              (dateRange.length > 0 && requestTimeStart && requestTimeEnd) && <Collapse
                size="small"
                items={[{
                  key: "preview",
                  label: "Preview",
                  children: <List
                    size="small"
                    dataSource={dateRange}
                    renderItem={(item) =>
                      <List.Item>
                        {dayjs(item).format("dddd")}, {item} @ {requestTimeStart} - {requestTimeEnd}
                      </List.Item>}
                  />
                }]}
                style={{ marginBottom: 24 }}
              />
            }


            <Form.Item
              name="users"
              label="Crew Members"
              required
            >
              <Select
                mode="multiple"
                placeholder="Crew Members"
                showSearch
                optionFilterProp="label"
                style={{
                  flex: 1,
                }}
                options={getCurrentJob(jobId)?.userAssignments.map((u) => { return { value: u.user.id, label: u.user.name } })}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
            </Form.Item>
          </Form>
        </Drawer>

        <Drawer
          title="Edit Schedule"
          placement="right"
          onClose={closeEditScheduleDrawer}
          open={openEditSchedule}
        >
          <Form
            form={editScheduleForm}
            onFinish={onEditScheduleFormSubmit}
            layout="vertical"
          >
            <Form.Item
              name="l_job"
              label="Job"
            >
              <Text strong>{selectedProductionRequest?.job?.name} - {selectedProductionRequest?.job?.description}</Text>
            </Form.Item>

            <Form.Item
              name="l_user"
              label="User"
            >
              <Text strong>{selectedProductionRequest?.user?.name}</Text>
            </Form.Item>

            <Form.Item
              name="requestTimes"
              label="Shift Time"
              required
              style={{ marginBottom: 0 }}
            >
              <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item
                  name="timeStart"
                >
                  <Input
                    type="time"
                    required
                  />
                </Form.Item>
                <Form.Item
                  name="timeEnd"
                  rules={[
                    {
                      required: true,
                      message: 'Please select end range!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const timeStartValue = getFieldValue("timeStart");
                        if (timeStartValue >= value) return Promise.reject("End time must be after start time!");

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    type="time"
                    required
                  />
                </Form.Item>
              </Space>
            </Form.Item>

            <Form.Item
              name="requestDate"
              label="Date"
              required
            >
              <Space style={{ display: 'flex' }} align="baseline">
                <Form.Item
                  name="dateStart"
                  style={{ marginBottom: 12 }}
                  rules={[
                    {
                      required: true,
                      message: 'Please select start range!',
                    },
                  ]}
                >
                  <Input
                    type="date"
                    onChange={(e) => {
                      const dateStartValue = e.target.value;
                      const dateEndValue = form.getFieldValue("dateEnd");

                      if (!dateEndValue) form.setFieldValue("dateEnd", e.target.value);
                    }}
                  />
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>Save</Button>
            </Form.Item>
          </Form>
        </Drawer>

        <Drawer
          title="Job Schedule"
          placement="right"
          onClose={closeSelectorDrawer}
          open={openSelector}
        >
          <Form
            form={selector}
            onFinish={onSelectorFormSubmit}
            layout="vertical"
          >
            <Form.Item
              name="job"
              label="Job"
              required
            >
              <Select
                style={{
                  width: "100%"
                }}
                onChange={onJobSelect}
                showSearch
                placeholder="Select a Job"
                optionFilterProp="children"
              >
                {jobs?.map((job) => <Option value={job.id.toString()} key={job.id} > {job.name} - {job.description} </Option>)}
              </Select>
            </Form.Item>
            <Form.Item
              name="week"
              label="Week"
              required
            >
              <DatePicker
                style={{
                  width: "100%"
                }}
                onChange={onAnchorDateSelect}
                picker="week" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">View</Button>
            </Form.Item>
          </Form>
        </Drawer>

        <Modal
          centered
          open={modalOpen}
          title="Preview Changes"
          onOk={onCrewBuilderFormSubmit}
          onCancel={() => {
            setModalOpen(false)
            setCrew([])
          }}
          onClose={() => {
            setModalOpen(false)
            setCrew([])
          }}
          okText={loading ? "Loading" : "Submit"}
          okButtonProps={{ disabled: loading }}
        >
          <div
            id="scrollableDiv"
            style={{
              height: "60vh",
              overflow: 'auto',
            }}
          >
            <List
              bordered
              dataSource={crew}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={item.name}
                    description={`${dayjs(item.requestAt).format("YYYY-MM-DD HH:mm")} to ${dayjs(item.requestEndsAt).format("HH:mm")}`}
                  />
                  {item.isValid && <Tag bordered={false} color="success">Valid</Tag>}
                  {!item.isValid && <Tag bordered={false} color="error">Conflict</Tag>}
                </List.Item>
              )}
            />
          </div>
          {crew.filter(p => !p.isValid)?.length > 0 && <Alert style={{ marginTop: 12 }} showIcon={true} type="warning" message={`There are ${crew.filter(p => !p.isValid)?.length} conflicts. Conflicted requests will be ignored once submitted.`} />}
        </Modal>

        <FloatButton.Group size="large" shape="square" style={{ right: 24, bottom: 120 }} >
          <FloatButton icon={<SyncOutlined />} onClick={openSelectorDrawer} />
          <FloatButton icon={<UsergroupAddOutlined />} onClick={openCrewBuilderDrawer} />
          <FloatButton.BackTop visibilityHeight={300} />
        </FloatButton.Group>
      </Content >
      {/* <MobileNav /> */}
    </ConfigProvider5 >
  )
}

export default App;