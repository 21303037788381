import React from 'react';
import { Route, Switch, HashRouter, useLocation, withRouter, Redirect, useHistory } from "react-router-dom";
import { Spin, Typography, Card, Button, Breadcrumb, Result, Layout } from 'antd';
import { LoadingOutlined, RobotOutlined, HomeOutlined } from '@ant-design/icons';
import { FormRole } from './contents/users/FormRole';
import { FormUser } from './contents/users/FormUser';
import { ListUsers } from './contents/users/ListUsers';
import { ListJobs } from './contents/jobs/ListJobs';
import { FormJob } from './contents/jobs/FormJob';
import { ViewJob } from './contents/jobs/ViewJob';
import { FormEquipment } from './contents/equipment/FormEquipment';
import { ListEquipment } from './contents/equipment/ListEquipment';
import { ListCostCodes } from './contents/cost_codes/ListCostCodes';
import { FormCostCode } from './contents/cost_codes/FormCostCode';
import { AssignUser } from './contents/jobs/AssignUser';
import { AssignEquipment } from './contents/jobs/AssignEquipment';
import { FormProduction } from './contents/production/FormProduction';
import { FormTimeRecord } from './contents/production/FormTimeRecord';
import { ListRoles } from './contents/users/ListRoles';
import { ForemanReport } from './contents/reports/ForemanReport';
import Login from './Login';
import Auth from './Auth';
import { ForemanReportCheck } from './contents/reports/ForemanReportCheck';
import { EquipmentUsageReport } from './contents/reports/EquipmentUsageReport';
import { ViewEquipment } from './contents/equipment/ViewEquipment';
import { PayrollReportCheck } from './contents/human_resources/payroll_records/PayrollReportCheck';
import { PayrollReport } from './contents/human_resources/payroll_records/PayrollReport';
import { FormCompany } from './contents/companies/FormCompany';
import { ListCompanies } from './contents/companies/ListCompanies';
import { ActiveEmployeesReport } from './contents/human_resources/payroll_records/ActiveEmployeesReport';
import ScrollToTop from './contents/shared/ScrollToTop';
import { TimeForEmployeesReport } from './contents/human_resources/TimeForEmployeesReport';
import { MissingTimeCardsReport } from './contents/human_resources/MissingTimecardsReport';
import { FormMaterialHauling } from './contents/production/FormMaterialHauling';
import { MaterialProductionReport } from './contents/material_hauling/MaterialProductionReport';
import { FormWorkType } from './contents/work_types/FormWorkType';
import { ListWorkTypes } from './contents/work_types/ListWorkTypes';
import { FormEquipmentCategory } from './contents/equipment_categories/FormEquipmentCategory';
import { ListEquipmentCategories } from './contents/equipment_categories/ListEquipmentCategories';
import { SpreadReportCheck } from './contents/reports/SpreadReportCheck';
import { SpreadReport } from './contents/reports/SpreadReport';
import { SubcontractorReport } from './contents/reports/SubcontractorReport';
import { SubcontractorReportCheck } from './contents/reports/SubcontractorReportCheck';
import { TimeForEmployeesReportV2 } from './contents/human_resources/TimeForEmployeesReportV2';
import { ManagerReportCheck } from './contents/reports/ManagerReportCheck';
import { ManagerReport } from './contents/reports/ManagerReport';

import CrewBuilder from './contents/mobile/CrewBuilder';
import MyTimeRequests from './contents/mobile/MyTimeRequests';
import MyTimeRecords from './contents/mobile/MyTimeRecords';
import { FormTimeRequests } from './contents/mobile/FormTimeRequests';

const { Title } = Typography;
const { Content } = Layout;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export class DashboardPaths extends React.Component {
    state = {
        isLoading: false,
    };

    constructor(props) {
        const user = Auth.getCurrentUser();
        console.log("Current User", user)

        super(props);
    }

    setLoading = (isLoading) => {
        this.setState({
            isLoading: isLoading
        });
    }

    setAuthentication = (user) => {
        this.setState({
            isLoading: false,
            isAuthenticated: true,
            user: user
        })
    }

    render() {

        const self = this;

        const spinner = <LoadingOutlined style={{ fontSize: 48 }} spin />

        return (
            <Spin indicator={spinner} spinning={this.state.isLoading}>
                <ScrollToTop />
                <Switch>
                    <Route path={["", "/home", "/index"]} exact render={(props) =>
                        <Content style={{ minHeight: "80vh" }}>
                            <Breadcrumb style={{ margin: '16px 0' }}>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                            </Breadcrumb>
                            <Card style={{ width: '100%' }}>
                                <Result
                                    icon={<HomeOutlined />}
                                    title="Welcome"
                                    subTitle={`${Auth.getCurrentUser().name}`}
                                    extra={
                                        <Button type="primary" href={"/#/app/my-schedule"} key="console">
                                            Go to App
                                        </Button>
                                    }
                                />
                            </Card>
                        </Content>
                    } />
                    <Route path="/companies" exact render={(props) => <ListCompanies setLoading={this.setLoading} />} />
                    <Route path="/companies/add" render={(props) => <FormCompany setLoading={this.setLoading} {...props} />} />
                    <Route path="/companies/:id/edit" render={(props) => <FormCompany id={props.id} setLoading={this.setLoading} {...props} />} />

                    <Route path="/users" exact render={(props) => <ListUsers setLoading={this.setLoading} />} />
                    <Route path="/users/add" render={(props) => <FormUser setLoading={this.setLoading} {...props} />} />
                    <Route path="/users/:id/edit" render={(props) => <FormUser id={props.id} setLoading={this.setLoading} {...props} />} />

                    <Route path="/roles" exact render={(props) => <ListRoles setLoading={this.setLoading} />} />
                    <Route path="/roles/:id/edit" exact render={(props) => <FormRole id={props.id} setLoading={this.setLoading} {...props} />} />
                    <Route path="/roles/add" exact render={(props) => <FormRole setLoading={this.setLoading} {...props} />} />

                    <Route path="/jobs" exact render={(props) => <ListJobs setLoading={this.setLoading} {...props} />} />
                    <Route path="/jobs/add" exact component={FormJob} />
                    <Route path="/jobs/users/add" exact component={AssignUser} />
                    <Route path="/jobs/equipment/add" exact render={(props) => <AssignEquipment setLoading={this.setLoading} {...props} />} />
                    <Route path="/jobs/productions/add" exact render={(props) => <FormProduction setLoading={this.setLoading} {...props} />} />
                    <Route path="/jobs/time-records/add" exact render={(props) => <FormTimeRecord setLoading={this.setLoading} {...props} />} />
                    <Route path="/jobs/productions/:id" exact render={(props) => <FormProduction setLoading={this.setLoading} {...props} />} />
                    <Route path="/jobs/time-records/:id" exact render={(props) => <FormTimeRecord setLoading={this.setLoading} {...props} />} />

                    <Route path="/jobs/:id" exact render={(props) => <ViewJob setLoading={this.setLoading} {...props} />} />
                    <Route path="/jobs/:id/edit" exact render={(props) => <FormJob id={props.id} setLoading={this.setLoading} {...props} />} />

                    <Route path="/equipment" exact render={(props) => <ListEquipment setLoading={this.setLoading} {...props} />} />
                    <Route path="/equipment/add" exact render={(props) => <FormEquipment setLoading={this.setLoading} {...props} />} />
                    <Route path="/equipment/:id" exact render={(props) => <ViewEquipment setLoading={this.setLoading} {...props} />} />
                    <Route path="/equipment/:id/edit" exact render={(props) => <FormEquipment id={props.id} setLoading={this.setLoading} {...props} />} />

                    <Route path="/cost-codes" exact render={(props) => <ListCostCodes setLoading={this.setLoading} {...props} />} />
                    <Route path="/cost-codes/add" exact render={(props) => <FormCostCode setLoading={this.setLoading} {...props} />} />
                    <Route path="/cost-codes/:id/edit" exact render={(props) => <FormCostCode id={props.id} setLoading={this.setLoading} {...props} />} />

                    <Route path="/reports" exact />
                    <Route path="/reports/foreman-report" exact render={(props) => <ForemanReportCheck setLoading={this.setLoading} {...props} />} />
                    <Route path="/reports/foreman-report/:id" exact render={(props) => {
                        return (
                            <ForemanReport id={props.id} setLoading={self.setLoading} {...props} />
                        )
                    }} />
                    <Route path="/reports/spread-report" exact render={(props) => <SpreadReportCheck setLoading={this.setLoading} {...props} />} />
                    <Route path="/reports/spread-report/:id" exact render={(props) => {
                        return (
                            <SpreadReport id={props.id} setLoading={self.setLoading} {...props} />
                        )
                    }} />

                    <Route path="/reports/subcontractor-report" exact render={(props) => <SubcontractorReportCheck setLoading={this.setLoading} {...props} />} />
                    <Route path="/reports/subcontractor-report/report" exact render={(props) => {
                        return (
                            <SubcontractorReport id={props.id} setLoading={self.setLoading} {...props} />
                        )
                    }} />

                    <Route path="/reports/equipment-usage-report" exact render={(props) => <EquipmentUsageReport setLoading={self.setLoading} {...props} />} />
                    <Route path="/reports/manager-query/" exact render={(props) => <ManagerReportCheck setLoading={this.setLoading} {...props} />} />
                    <Route path="/reports/manager-report/" exact render={(props) => <ManagerReport setLoading={this.setLoading} {...props} />} />

                    <Route path="/human-resources/payroll-query" exact render={(props) => <PayrollReportCheck setLoading={this.setLoading} {...props} />} />
                    <Route path="/human-resources/payroll-report" exact render={(props) => <PayrollReport setLoading={this.setLoading} {...props} />} />
                    <Route path="/human-resources/active-employees-report" exact render={(props) => <ActiveEmployeesReport setLoading={this.setLoading} {...props} />} />
                    <Route path="/human-resources/employee-time-records-report" exact render={(props) => <TimeForEmployeesReport setLoading={this.setLoading} {...props} />} />
                    <Route path="/human-resources/v2/employee-time-records-report/" exact render={(props) => <TimeForEmployeesReportV2 setLoading={this.setLoading} {...props} />} />
                    <Route path="/human-resources/missing-time-records-report" exact render={(props) => <MissingTimeCardsReport setLoading={this.setLoading} {...props} />} />

                    <Route path="/material-hauling/add" exact render={(props) => <FormMaterialHauling setLoading={this.setLoading} {...props} />} />
                    <Route path="/material-hauling/material-production-report" exact render={(props) => <MaterialProductionReport setLoading={this.setLoading} {...props} />} />

                    <Route path="/contents/work-types/add" exact render={(props) => <FormWorkType setLoading={this.setLoading} {...props} />} />
                    <Route path="/contents/work-types" exact render={(props) => <ListWorkTypes setLoading={this.setLoading} {...props} />} />
                    <Route path="/contents/work-types/:id/edit" exact render={(props) => <FormWorkType id={props.id} setLoading={this.setLoading} {...props} />} />

                    <Route path="/human-resources/payroll-query" exact render={(props) => <PayrollReportCheck setLoading={this.setLoading} {...props} />} />
                    <Route path="/human-resources/payroll-report" exact render={(props) => <PayrollReport setLoading={this.setLoading} {...props} />} />
                    <Route path="/human-resources/active-employees-report" exact render={(props) => <ActiveEmployeesReport setLoading={this.setLoading} {...props} />} />
                    <Route path="/human-resources/employee-time-records-report" exact render={(props) => <TimeForEmployeesReport setLoading={this.setLoading} {...props} />} />
                    <Route path="/human-resources/missing-time-records-report" exact render={(props) => <MissingTimeCardsReport setLoading={this.setLoading} {...props} />} />

                    <Route path="/material-hauling/add" exact render={(props) => <FormMaterialHauling setLoading={this.setLoading} {...props} />} />
                    <Route path="/material-hauling/material-production-report" exact render={(props) => <MaterialProductionReport setLoading={this.setLoading} {...props} />} />

                    <Route path="/contents/work-types/add" exact render={(props) => <FormWorkType setLoading={this.setLoading} {...props} />} />
                    <Route path="/contents/work-types" exact render={(props) => <ListWorkTypes setLoading={this.setLoading} {...props} />} />
                    <Route path="/contents/work-types/:id/edit" exact render={(props) => <FormWorkType id={props.id} setLoading={this.setLoading} {...props} />} />

                    <Route path="/contents/equipment-categories/add" exact render={(props) => <FormEquipmentCategory setLoading={this.setLoading} {...props} />} />
                    <Route path="/contents/equipment-categories" exact render={(props) => <ListEquipmentCategories setLoading={this.setLoading} {...props} />} />
                    <Route path="/contents/equipment-categories/:id/edit" exact render={(props) => <FormEquipmentCategory id={props.id} setLoading={this.setLoading} {...props} />} />

                    {Auth.hasPermission('web.crew_builder') && <Route path="/app/crew-builder" exact render={(props) => <CrewBuilder setLoading={this.setLoading} {...props} loading={this.state.isLoading} />} />}
                    {Auth.hasPermission('web.timecards') && <Route path="/app/my-schedule" exact render={(props) => <MyTimeRequests setLoading={this.setLoading} {...props} loading={this.state.isLoading} />} />}
                    {Auth.hasPermission('web.timecards') && <Route path="/app/time-record-form" exact render={(props) => <FormTimeRequests setLoading={this.setLoading} {...props} loading={this.state.isLoading} />} />}
                    {Auth.hasPermission('web.timecards') && <Route path="/app/time-record-form/:id/edit" render={(props) => <FormTimeRequests id={props.id} setLoading={this.setLoading} {...props} loading={this.state.isLoading} />} />}
                    {Auth.hasPermission('web.timecards') && <Route path="/app/my-records" exact render={(props) => <MyTimeRecords setLoading={this.setLoading} {...props} loading={this.state.isLoading} />} />}

                    {Auth.hasPermission('productions.create') && <Route path="/time-records/add" exact render={(props) => <FormTimeRequests setLoading={this.setLoading} {...props} loading={this.state.isLoading} isWeb={true}/>} />}
                    {Auth.hasPermission('productions.create') && <Route path="/time-records/:id/edit" exact render={(props) => <FormTimeRequests setLoading={this.setLoading} {...props} loading={this.state.isLoading} isWeb={true} />} />}

                    <Route path="/logout" exact render={() => {
                        Auth.logout();
                        localStorage.clear();
                        window.location.reload();
                    }} />

                    <Route render={() =>
                        <>
                            <Breadcrumb style={{ margin: '16px 0' }}>
                                <Breadcrumb.Item>Error</Breadcrumb.Item>
                                <Breadcrumb.Item>404</Breadcrumb.Item>
                            </Breadcrumb>
                            <Card style={{ width: '600px' }}>
                                <Result
                                    icon={<RobotOutlined />}
                                    title="404"
                                    subTitle={`Ooops! The page you are looking for could not be found.`}
                                />

                            </Card>
                        </>
                    } />
                </Switch>
            </Spin>
        );
    }
}