import React from 'react';
import { Menu, Tag, Badge } from 'antd';
import { NavLink, withRouter, Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import Auth from './Auth'

import {
    HomeOutlined,
    TeamOutlined,
    BankOutlined,
    HourglassOutlined,
    DeploymentUnitOutlined,
    DollarOutlined,
    PieChartOutlined,
    UserOutlined,
    ToolOutlined,
    AuditOutlined,
    GoldOutlined,
    ProfileOutlined,
    ThunderboltOutlined,
    MobileOutlined
} from '@ant-design/icons';

const { SubMenu } = Menu;

class DashboardMenu extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired
    }

    componentDidUpdate(prevProps) {

        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        Auth.getLoginState();
    }

    render() {
        Auth.getLoginState();
        const { location } = this.props;

        return (
            <Menu
                id="mainNavigation"
                theme="dark"
                defaultSelectedKeys={['/']}
                selectedKeys={[location.pathname]}
                mode="inline"
            >
                <Menu.Item key="/" icon={<HomeOutlined />}>
                    <NavLink to="/">
                        Home
                    </NavLink>
                </Menu.Item>

                {Auth.hasPermission("companies.all") &&
                    <SubMenu key="#/companies" icon={<DeploymentUnitOutlined />} title="Companies">
                        <Menu.Item key="/companies">
                            <NavLink to="/companies">
                                View Companies
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="/companies/add">
                            <NavLink to="/companies/add">
                                Add Company
                            </NavLink>
                        </Menu.Item>
                    </SubMenu>
                }
                {
                    Auth.hasPermission('users.all') &&
                    <SubMenu key="#/users" icon={<TeamOutlined />} title="Users">
                        {
                            Auth.hasPermission('users.read') &&
                            <Menu.Item key="/users">
                                <NavLink to="/users">
                                    View Users
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            Auth.hasPermission('users.create') &&
                            <Menu.Item key="/users/add">
                                <NavLink to="/users/add">
                                    Add User
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            Auth.hasPermission('roles.read') &&
                            <Menu.Item key="/roles">
                                <NavLink to="/roles">
                                    View Roles
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            Auth.hasPermission('roles.read') &&
                            <Menu.Item key="/roles/add">
                                <NavLink to="/roles/add">
                                    Add Role
                                </NavLink>
                            </Menu.Item>
                        }
                    </SubMenu>
                }
                {
                    Auth.hasPermission('jobs.all') &&
                    <SubMenu key="#/jobs" icon={<BankOutlined />} title="Jobs">
                        {
                            // Constants.Permissions.Jobs.Read
                            Auth.hasPermission('jobs.read') &&
                            <Menu.Item key="/jobs">
                                <NavLink to="/jobs">
                                    View Jobs
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            // Constants.Permissions.Jobs.Create
                            Auth.hasPermission("jobs.create") &&
                            <Menu.Item key="/jobs/add">
                                <NavLink to="/jobs/add">
                                    Add Job
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            // Constants.Permissions.Jobs.Create
                            Auth.hasPermission('jobs.create') &&
                            <Menu.Item key="/jobs/users/add">
                                <NavLink to="/jobs/users/add">
                                    Add Employee to Job
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            // Constants.Permissions.Productions.Create
                            Auth.hasPermission('productions.create') &&
                            <Menu.Item key="/jobs/productions/add">
                                <NavLink to="/jobs/productions/add">
                                    Add Production
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            // Constants.Permissions.Productions.Create
                            Auth.hasPermission('productions.create') &&
                            <Menu.Item key="/jobs/time-records/add">
                                <NavLink to="/jobs/time-records/add">
                                    Add Time Records
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            // Constants.Permissions.Productions.Create
                            Auth.hasPermission('productions.create') &&
                            <Menu.Item key="/time-records/add">
                                <NavLink to="/time-records/add">
                                    <Badge count={<><ThunderboltOutlined style={{ color: 'gold' }} /> </>} />
                                    Add Time Records 
                                </NavLink>
                            </Menu.Item>
                        }
                    </SubMenu>
                }
                {
                    Auth.hasPermission('equipment.all') &&
                    <SubMenu key="#/equipment" icon={<ToolOutlined />} title="Equipment">
                        {
                            Auth.hasPermission('equipment.read') &&
                            <Menu.Item key="/equipment">
                                <NavLink to="/equipment">
                                    View Equipment
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            Auth.hasPermission('equipment.create') &&
                            <Menu.Item key="/equipment/add">
                                <NavLink to="/equipment/add">
                                    Add Equipment
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            Auth.hasPermission('equipment.create') &&
                            <Menu.Item key="/jobs/equipment/add">
                                <NavLink to="/jobs/equipment/add">
                                    Add Equipment to Job
                                </NavLink>
                            </Menu.Item>
                        }
                    </SubMenu>
                }
                {
                    Auth.hasPermission('cost_codes.all') &&
                    <SubMenu key="#/cost-codes" icon={<DollarOutlined />} title="Cost Codes">
                        {
                            Auth.hasPermission('cost_codes.read') &&
                            <Menu.Item key="/cost-codes">
                                <NavLink to="/cost-codes">
                                    View Cost Codes
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            Auth.hasPermission('cost_codes.create') &&
                            <Menu.Item key="/cost-codes/add">
                                <NavLink to="/cost-codes/add">
                                    Add Cost Codes
                                </NavLink>
                            </Menu.Item>
                        }
                    </SubMenu>
                }

                {
                    Auth.hasPermission('material_hauling.all') &&
                    <SubMenu key="#/material-hauling" icon={<GoldOutlined />} title="Material Hauling">
                        {
                            Auth.hasPermission('productions.create') &&
                            <Menu.Item key="/jobs/productions/add-material-hauling">
                                <NavLink to="/jobs/productions/add">
                                    Add Production
                                </NavLink>
                            </Menu.Item>
                        }
                        <Menu.Item key="/material-hauling/add">
                            <NavLink to="/material-hauling/add">
                                Add Material Hauling
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="/material-hauling/material-production-report">
                            <NavLink to="/material-hauling/material-production-report">
                                Stockpile Report
                            </NavLink>
                        </Menu.Item>
                    </SubMenu>
                }
                {
                    Auth.hasPermission('reports.all') &&
                    <SubMenu key="#/reports" icon={<PieChartOutlined />} title="Reports">
                        {
                            Auth.hasPermission('reports.foreman_report') &&
                            <Menu.Item key="/reports/foreman-report">
                                <NavLink to="/reports/foreman-report">
                                    Foreman Report
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            Auth.hasPermission('reports.equipment_usage_report') &&
                            <Menu.Item key="/reports/equipment-usage-report">
                                <NavLink to="/reports/equipment-usage-report">
                                    Equipment Usage Report
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            Auth.hasPermission('reports.spread_report') &&
                            <Menu.Item key="/reports/spread-report">
                                <NavLink to="/reports/spread-report">
                                    Spread Report
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            Auth.hasPermission('reports.subcontractor_report') &&
                            <Menu.Item key="/reports/subcontractor-report">
                                <NavLink to="/reports/subcontractor-report">
                                    Subcontractor Report
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            Auth.hasPermission('reports.manager_report') &&
                            <Menu.Item key="/reports/manager-query">
                                <NavLink to="/reports/manager-query">
                                    Manager Report
                                </NavLink>
                            </Menu.Item>
                        }
                    </SubMenu>
                }
                {
                    Auth.hasPermission('human_resources.all') &&
                    <SubMenu key="#/human-resources" icon={<AuditOutlined />} title="HR">
                        {
                            Auth.hasPermission('reports.payroll_report') &&
                            < Menu.Item key="/human-resources/payroll-query">
                                <NavLink to="/human-resources/payroll-query">
                                    Payroll Report
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            Auth.hasPermission('reports.active_employees_report') &&
                            <Menu.Item key="/human-resources/active-employees-report">
                                <NavLink to="/human-resources/active-employees-report">
                                    Active Employees Report
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            Auth.hasPermission('reports.timecards_report') &&
                            <Menu.Item key="/human-resources/employee-time-records-report">
                                <NavLink to="/human-resources/employee-time-records-report">
                                    Timecards Report
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            Auth.hasPermission('reports.timecards_report_v2') &&
                            <Menu.Item key="/human-resources/v2/employee-time-records-report">
                                <NavLink to="/human-resources/v2/employee-time-records-report">
                                    <Badge count={<><ThunderboltOutlined style={{ color: 'gold' }} /> </>} />
                                    Timecards Report
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            Auth.hasPermission('reports.missing_timecards_report') &&
                            <Menu.Item key="/human-resources/missing-time-records-report">
                                <NavLink to="/human-resources/missing-time-records-report">
                                    Missing Timecards Report
                                </NavLink>
                            </Menu.Item>
                        }
                    </SubMenu>
                }
                {
                    Auth.hasPermission('contents.all') &&
                    <SubMenu key="#/contents" icon={<ProfileOutlined />} title="Contents">
                        {
                            Auth.hasPermission('contents.work_types') &&
                            <Menu.Item key="/contents/work-types">
                                <NavLink to="/contents/work-types">
                                    View Work Types
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            Auth.hasPermission('contents.work_types') &&
                            <Menu.Item key="/contents/work-types/add">
                                <NavLink to="/contents/work-types/add">
                                    Add Work Type
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            Auth.hasPermission('contents.equipment_categories') &&
                            <Menu.Item key="/contents/equipment-categories">
                                <NavLink to="/contents/equipment-categories">
                                    View Equipment Categories
                                </NavLink>
                            </Menu.Item>
                        }
                        {
                            Auth.hasPermission('contents.equipment_categories') &&
                            <Menu.Item key="/contents/equipment-categories/add">
                                <NavLink to="/contents/equipment-categories/add">
                                    Add Equipment Category
                                </NavLink>
                            </Menu.Item>
                        }


                    </SubMenu>
                }

                <SubMenu key="#/app" icon={<MobileOutlined />} title="App">
                    {
                        Auth.hasPermission('web.crew_builder') &&
                        <Menu.Item key="/app/crew-builder">
                            <NavLink to="/app/crew-builder">
                                Crew Builder
                            </NavLink>
                        </Menu.Item>
                    }
                    {
                        Auth.hasPermission('web.timecards') &&
                        <Menu.Item key="/app/my-schedule">
                            <NavLink to="/app/my-schedule">
                                My Schedule
                            </NavLink>
                        </Menu.Item>
                    }
                    {
                        Auth.hasPermission('web.timecards') &&
                        <Menu.Item key="/app/my-records">
                            <NavLink to="/app/my-records">
                                My Records
                            </NavLink>
                        </Menu.Item>
                    }
                </SubMenu>

                <SubMenu theme="light" key="#/current-user" icon={<UserOutlined />} title={Auth.getCurrentUser().name}>
                    <Menu.Item key="/logout">
                        <NavLink to="/logout">
                            Log Out
                        </NavLink>
                    </Menu.Item>
                </SubMenu>

            </Menu >
        );
    }
}

export const DashboardMenuWithRouter = withRouter(DashboardMenu);
