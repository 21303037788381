import { CheckOutlined, ExclamationCircleOutlined, SearchOutlined, LeftOutlined, RightOutlined, CalendarOutlined } from '@ant-design/icons';
import { Alert, Checkbox, Breadcrumb, Button, Flex, Tag, Typography, Input, Modal, Space, Table, Dropdown, Menu, Select, notification, Result, Card, Form, DatePicker, Badge, ConfigProvider as ConfigProvider5,} from 'antd-v5';
import exportFromJSON from 'export-from-json';
import React, { useContext, useState, useEffect, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import dayjs from 'dayjs';
import moment from 'moment';

const { confirm } = Modal;
const { Option } = Select;
const { Text } = Typography;


const EditableContext = React.createContext(null);

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        placement: 'topRight',
    });
};

export class ForemanReport extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        productionRecords: [],
        jobId: this.props.match.params.id,
        timeRecords: [],
        jobs: [],
        isLoaded: false,
        error: null,
        job: {},
        paginationSettings: { position: ["bottomLeft"] },
        workTypes: {},
        equipments: [],
        editProductionId: null,
        isMissingTimecards: null,
    };

    async componentDidMount() {
        await this.fetchTimeRequests();
        await this.fetchJob();
    }

    async componentDidUpdate(prev) {
        if (this.props.location.search != prev.location.search) {
            await this.fetchTimeRequests();
            await this.fetchJob();
        }
    }

    async fetchTimeRequests () {
        const self = this;
        this.props.setLoading(false)
        const query = new URLSearchParams(this.props.location.search);
        const date = moment(query.get('date'));
        console.log("DATE", date)
        const jobId = this.props.match.params.id;
        
        var start = date.toISOString();
        var end = moment(start).endOf("day").toISOString()

        await fetch(`/api/jobs/${jobId}/production-requests?isPending=true&requestAt=${start}&requestEndsAt=${end}`)
            .then((response) => response.json())
            .then((response) => {

                const pending = response.filter(x => x.job.id == jobId && (x.productionId == null || x.productionId == 0))
                const allPending = response.filter(x => x.job.id == jobId);
                if (pending.length == 0) {
                    self.setState({
                        isMissingTimecards: false
                    })
                }
                else {
                    self.setState({
                        isMissingTimecards: true
                    })
                }

            })
            .catch(error => {
                alert("Erorr");
            })
            .finally(() => {
                this.props.setLoading(false)
            });
    }

    async fetchJob() {
        this.props.setLoading(true);

        await fetch(`/api/jobs/${this.props.match.params.id}?includeCostCodes=true&excludeDone=false`)
            .then((response) => response.json())
            .then(async (job) => {
                this.props.setLoading(false);
                this.setState({
                    job: job
                })
                this.props.setLoading(false);
                await this.fetchProduction()
            })
            .catch(error => {
                this.props.setLoading(false);

            });
    }

    async fetchProduction() {
        const setLoading = this.props.setLoading;
        setLoading(true);
        const query = new URLSearchParams(this.props.location.search);
        let date = dayjs(query.get('date'));

        let start = date;
        let end = date.endOf("day");

        await fetch(`api/jobs/${this.state.jobId}/productions?includeJob=true&includeCostCodes=true&producedStartsAt=${date.toISOString()}&producedEndsAt=${end.toISOString()}&includeProductionRequests=true`)
            .then(response => response.json())
            .then(
                (result) => {
                    let production = result.map(e => ({ ...e, key: e.id }));

                    this.setState({
                        production: production,
                    });


                    const timeRecords = production?.filter(x => x.costCode?.isToTrackEmployeeAndEquipmentTime);
                    let eq = [];
                    let mappedTimeRecords = timeRecords.map(x => {
                        eq = x.job?.equipmentUtilization.map(x => x.equipment)
                        return {
                            job: x.job?.name,
                            key: x.id,
                            costCode: x.costCode.name,
                            costCodeDescription: `${x.costCode.description}`,
                            producedAt: x.producedAt,
                            producedEndsAt: x.producedEndsAt,
                            quantity: x.quantity,
                            description: x.description,
                            workType: x.workType?.name,
                            workTypeObj: x.workType,
                            costCodeId: x.costCodeId,
                            isApproved: x.isApproved,
                            productionEquipments: x.productionEquipments,
                            type: x.type,
                        name: x.user?.name,
                            user: x.user,
                            costCodeObj: x.costCode,
                            jobObj: x.job,
                            exportedAt: x.exportedAt,
                            isRequested: x.productionRequests?.length > 0,
                            isLunchbreakTaken: x.isLunchbreakTaken,
                            productionRequestId: x.productionRequestId,
                            productionRequest: x.productionRequest
                        }
                    })
                    console.log("Mapped Time Records", mappedTimeRecords)

                    this.setState({
                        equipments: eq
                    })

                    mappedTimeRecords = mappedTimeRecords.filter(x => x.type == "Time Record");

                    const productionRecords = production?.filter(x => x.costCode?.isToTrackProduction);
                    let mappedProductionRecords = productionRecords.map(x => {
                        return {
                            job: x.job.name,
                            key: x.id,
                            costCode: `${x.costCode.name}`,
                            costCodeDescription: `${x.costCode.description}`,
                            producedAt: x.producedAt,
                            producedEndsAt: x.producedEndsAt,
                            quantity: x.quantity,
                            unit: x.costCode.unit,
                            description: x.description,
                            workType: x.workType?.name,
                            type: x.type,
                            name: x.user?.name,
                            costCodeObj: x.costCode,
                            jobObj: x.job
                        }
                    })

                    mappedProductionRecords = mappedProductionRecords.filter(x => x.type == null)

                    mappedTimeRecords = mappedTimeRecords.sort((a, b) => a.name.localeCompare(b.name))
                    mappedProductionRecords = mappedProductionRecords.sort((a, b) => a.name.localeCompare(b.name))

                    this.setState({
                        timeRecords: mappedTimeRecords,
                        productionRecords: mappedProductionRecords
                    })

                    console.log("TR", mappedTimeRecords)
                    console.log("PR", mappedProductionRecords)

                    fetch(`/api/jobs/${this.props.match.params.id}`)
                        .then((response) => response.json())
                        .then((job) => {
                            this.props.setLoading(false);

                            this.setState({
                                job: job
                            })
                            this.props.setLoading(false);
                        })
                        .catch(error => {
                            this.props.setLoading(false);

                        });

                    fetch('/api/work-types')
                        .then((response) => response.json())
                        .then((workTypesResult) => this.setState({ workTypes: workTypesResult }))

                },
                (error) => {
                    this.setState({
                        error
                    });
                    setLoading(false);
                }
            );
    }

    handleCopy = (record) => {
        const url = `#/time-records/add?job_id=${record.jobObj.id}&costCodeId=${record.costCodeObj.id}&userId=${record.user.id}&quantity=${record.quantity}&workType=${record.workType}&description=${"(Copy) " + record.description}&producedAt=${moment(record.producedAt).format("YYYY-MM-DD")} `;
        window.location.href = url
    }

    handleSave = (row) => {
        const isProductionEquipment = row.isProductionEquipment;

        const isProduction = row.unit != undefined;

        if (isProductionEquipment) {
            row.key = row.productionId;
        }

        const newData = !isProduction ? [...this.state.timeRecords] : [...this.state.productionRecords];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];

        newData.splice(index, 1, { ...item, ...row });

        if (!isProduction) {
            this.setState({
                timeRecords: newData,
            });
        }
        else {
            this.setState({
                productionRecords: newData,
            });
        }
        this.props.setLoading(true);

        if (isProductionEquipment) {

            console.log("EQUIPMENT", {
                id: item.key,
                equipmentId: row.equipmentId,
                litersOfFuel: row.litersOfFuel,
                hoursUsed: row.hoursUsed,
                notes: row.notes
            })

            fetch(`api/jobs/production-equipments/${row.id}`, {
                method: 'PUT', body: JSON.stringify({
                    equipmentId: row.equipmentId,
                    litersOfFuel: row.litersOfFuel,
                    hoursUsed: row.hoursUsed,
                    notes: row.notes
                }), mode: 'cors', headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(result => {
                    openNotificationWithIcon('success', 'Success', 'Record has been updated!');
                    window.location.reload()
                    this.props.setLoading(false);
                })
                .catch(result => {
                    openNotificationWithIcon('error', 'Error', 'Something went wrong!');
                    this.props.setLoading(false);
                });

            return;
        }


        fetch(`api/jobs/productions/${item.key}`, {
            method: 'PUT',
            body: JSON.stringify(row),
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {
                openNotificationWithIcon('success', 'Success', 'Record has been updated!');
                // this.props.setLoading(false);
            })
            .catch(result => {
                openNotificationWithIcon('error', 'Error', 'Something went wrong!');
                // this.props.setLoading(false);
            })
            .finally(() => {
                this.fetchProduction();
            });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex} `}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };


    handleDelete = record => {
        const self = this;

        Modal.confirm({
            title: 'Are you sure delete this record?',
            icon: <ExclamationCircleOutlined />,
            content: 'Click "Yes" to continue.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                fetch(`api/jobs/productions/${record.key}`, {
                    method: 'DELETE', mode: 'cors', headers: { 'Content-Type': 'application/json' }
                })
                    .then(response => response.json())
                    .then(operation => {
                        if (operation.isSuccessful) {
                            self.fetchProduction();

                            Modal.success({
                                title: 'Success',
                                content: 'Record has been deleted.',
                                icon: <CheckOutlined />,
                                okText: 'Yes',
                            })
                        }
                    })

            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }



    render() {
        const self = this;
        const EditableTimeRecordRow = ({ index, ...props }) => {
            const [form] = Form.useForm();
            return (
                <Form form={form} component={false}>
                    <EditableContext.Provider value={form}>
                        <tr {...props} />
                    </EditableContext.Provider>
                </Form>
            );
        };


        const EditableTimeRecordCell = ({
            title,
            editable,
            children,
            dataIndex,
            record,
            handleSave,
            ...restProps
        }) => {

            if (record?.exportedAt) editable = false;

            const [editing, setEditing] = useState(false);
            const inputRef = useRef(null);
            const form = useContext(EditableContext);
            useEffect(() => {
                if (editing) {
                    inputRef.current.focus();
                }
            }, [editing]);

            const toggleEdit = () => {
                setEditing(!editing);
                form.setFieldsValue({
                    [dataIndex]: title == "Date" ? moment(record[dataIndex]) : record[dataIndex],
                });
            };

            const save = async () => {
                try {
                    console.log("RRRR", record)
                    const values = await form.validateFields();
                    toggleEdit();

                    handleSave({ ...record, ...values });
                } catch (errInfo) {
                    console.log('Save failed:', errInfo);
                }
            };

            let childNode = children;

            const defaultNode = <div
                                    className="editable-cell-value-wrap"
                                    onClick={toggleEdit}
                                >
                                    {children}
                                </div>

            if (editable) {
                childNode = editing ? (
                    <Form.Item
                        style={{
                            margin: 0,
                        }}
                        name={dataIndex}
                        rules={[
                            {
                                required: true,
                                message: `${title} is required.`,
                            },
                        ]}
                    >

                        {
                            (() => {
                                if (title == "Type of Work") {
                                    const workType = record.workTypeObj;

                                    return (<Select
                                        ref={inputRef}
                                        onSelect={(value, option) => {
                                            console.log(option)
                                            record.workType = option.children;
                                            record.workTypeId = value;

                                            console.log(record)
                                            handleSave(record);
                                        }}
                                        showSearch
                                        placeholder="Select Type of Work"
                                        optionFilterProp="children"
                                    >
                                        {self.state.workTypes?.map((c) => <Option value={c.id.toString()} key={c.id}>{c.name}</Option>)}
                                    </Select>)
                                }

                                if (title == "Equipment") {
                                    const equipmentUtilization = this.state.equipments;
                                    console.log({ test: 123, job: record.jobObj })
                                    return (
                                        <Select
                                            ref={inputRef}
                                            showSearch
                                            onSelect={(value, option) => {
                                                console.log(option)
                                                record.equipment = option.children[0];
                                                record.equipmentId = value;
                                                handleSave(record);
                                            }}

                                            style={{ width: 300 }}
                                            optionFilterProp="children"
                                        >
                                            {equipmentUtilization?.map(x => <Option value={x.id.toString()} key={x.id}>{x.name} - {x.description}</Option>)}
                                        </Select>
                                    )
                                }

                                if (title == "Cost Code") {
                                    const costCode = record.costCodeObj;
                                    console.log({ test: 123, job: record.jobObj })
                                    return (
                                        <Select
                                            ref={inputRef}
                                            showSearch
                                            onSelect={(value, option) => {
                                                console.log(option)
                                                record.costCode = option.children[0];
                                                record.costCodeId = value;
                                                handleSave(record);

                                            }}

                                            style={{ width: 120 }}
                                        >
                                            {record.jobObj?.costCodes?.map(x => <Option value={x.id.toString()}>{x.name} - {x.description}</Option>)}
                                        </Select>
                                    )
                                }

                                if (title == "Date") {
                                    return (
                                        <DatePicker ref={inputRef} onBlur={save} onChange={save} />
                                    )
                                }

                                return (
                                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                                )
                            })()
                        }


                    </Form.Item>
                ) : (
                    defaultNode
                );
            }

            return <td {...restProps}>{childNode}</td>;
        };

        let timeColumns = [
            {
                title: 'Approved',
                dataIndex: 'isApproved',
                key: 'isApproved',
                sortDirections: ['descend', 'ascend'],
                render: (p, record) => {

                    const getOptionValue = (option) => {
                        let value = null;
                        if (option == "N/P") value = null;
                        if (option == "Approved") value = true;
                        if (option == "Declined") value = false;
                        return value;
                    }

                    const getOptionName = (value) => {
                        let name = "N/P";
                        if (value == null) name = "N/P";
                        if (value == true) name = "Approved";
                        if (value == false) name = "Declined";
                        return name;
                    }

                    return (
                        <>
                            {!record.exportedAt ? <Badge dot={p == null}>
                                <Select
                                    disabled={record.exportedAt}
                                    placeholder="N/P"
                                    defaultValue={getOptionName(p) != "N/P" ? getOptionName(p) : null}
                                    style={{ width: 120, fontWeight: 500, color: (p ? "#52c41a" : (p == false ? "#ff4d4f" : "#1f1f1f")) }}
                                    onChange={(value) => {
                                        const isApproved = getOptionValue(value);
                                        record.isApproved = isApproved;
                                        this.handleSave(record);
                                    }
                                    }>
                                    <Option value="N/P">N/P</Option>
                                    <Option value="Approved">Yes</Option>
                                    <Option value="Declined">No</Option>
                                </Select>
                            </Badge>
                                :
                                <Text style={{ width: 120, fontWeight: 500, color: (p ? "#52c41a" : (p == false ? "#ff4d4f" : "#1f1f1f")) }}>{
                                    p == true ? "Yes" : "No"
                                }</Text>
                            }

                        </>
                    )
                },
            },
            {
                title: 'Date',
                dataIndex: 'producedAt',
                key: 'producedAt',
                editable: true,
                render: producedAt => <>{moment(producedAt).format("YYYY-MM-DD")}</>,
            },
            {
                title: 'Cost Code',
                dataIndex: 'costCode',
                ...this.getColumnSearchProps('costCode'),
                key: 'costCode',
                sorter: (a, b) => ('' + a.costCode).localeCompare(b.costCode),
                sortDirections: ['descend', 'ascend'],
                editable: true,
            },
            {
                title: 'Cost Code Description',
                dataIndex: 'costCodeDescription',
                ...this.getColumnSearchProps('costCodeDescription'),
                key: 'costCodeDescription',
                sorter: (a, b) => ('' + a.costCodeDescription).localeCompare(b.costCodeDescription),
                sortDirections: ['descend', 'ascend'],
                editable: false,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
                sorter: (a, b) => ('' + a.name).localeCompare(b.name),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Type of Work',
                dataIndex: 'workType',
                ...this.getColumnSearchProps('workType'),
                key: 'workType',
                editable: true,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Hours',
                dataIndex: 'quantity',
                key: 'quantity',
                sortDirections: ['descend', 'ascend'],
                editable: true,
                align: "center",
                render: (value) => value,
            },
            {
                title: 'In',
                dataIndex: 'producedAt',
                key: 'producedEndsAt',
                render: (value, record) => {
                    if (!record.producedEndsAt) return;
                    return !record.exportedAt ? (
                        <Input 
                        type="time"
                        defaultValue={dayjs(record.producedAt).format("HH:mm")}
                        onChange={(e) => {
                            const date = dayjs(record.producedAt).format("YYYY-MM-DD");
                            const time = e.target.value;
                            const value = dayjs(`${date}T${time}`);
                            record.producedAt = value.toISOString();
                            this.handleSave(record);
                        }}/>
                    ) : dayjs(record.producedAt).format("HH:mm");
                }
            },
            {
                title: 'Out',
                dataIndex: 'producedEndsAt',
                key: 'producedEndsAt',
                render: (value, record) => {
                    if (!record.producedEndsAt) return;

                    const originalStart = dayjs(record.producedAt);
                    const originalEnd = dayjs(record.producedEndsAt);
                    const isOvernight = originalEnd.isAfter(originalStart, "day");

                    return !record.exportedAt ? (
                            <Input 
                                type="time"
                                defaultValue={dayjs(record.producedEndsAt).format("HH:mm")}
                                disabled={record.exportedAt}
                                onChange={(e) => {
                                    const date = dayjs(record.producedAt).format("YYYY-MM-DD");
                                    const time = e.target.value;
                                    let newEndsAt = dayjs(`${date}T${time}`);

                                    if (newEndsAt.isBefore(dayjs(record.producedAt))) 
                                    {
                                        newEndsAt = newEndsAt.add(1, "day");
                                    }

                                    record.producedEndsAt = newEndsAt.toISOString();
                                    this.handleSave(record);
                                }}/>
                            ) : dayjs(record.producedEndsAt).format("HH:mm");
                    ;
                }
            },
            {
                title: 'Lunch?',
                dataIndex: 'isLunchbreakTaken',
                key: 'isLunchbreakTaken',
                align: "center",
                render: (value, record) => {
                    console.log({value, record})
                    switch (record.isLunchbreakTaken) {
                        case true:
                        case false:
                            record.isLunchbreakTaken = record.isLunchbreakTaken;
                            break;
                        default:
                            const x = dayjs(record.producedAt);
                            const y = dayjs(record.producedEndsAt);
                            const diff = y.diff(x, "hours", true)?.toFixed(2);
                            const hasMatchingLunchbreakAdjustment = (diff - record.quantity) == 0.5;
                            record.isLunchbreakTaken = diff != record.quantity && hasMatchingLunchbreakAdjustment;
                            break;
                    }

                    return <Checkbox 
                                onChange={(e) => {
                                    record.isLunchbreakTaken = e.target.checked;
                                    this.handleSave(record);
                                }} 
                                disabled={record.exportedAt}
                                defaultChecked={record.isLunchbreakTaken}
                            />
                },
            },
            {
                title: 'Comments',
                dataIndex: 'description',
                ...this.getColumnSearchProps('description'),

                editable: true,
                key: 'description',
                render: note => <span>{note?.length > 500 ? `${note.substr(0, 500)} ...` : note}</span>,
            },
            {
                title: 'Exported',
                dataIndex: 'exportedAt',
                // ...this.getColumnSearchProps('description'),

                // editable: true,
                key: 'exportedAt',
                filters: [
                    {
                        text: <span>Exported</span>,
                        value: true,
                    },
                    {
                        text: <span>Pending</span>,
                        value: false,
                    },
                ],
                onFilter: (value, record) => !!record.exportedAt == value,
                filterSearch: (input, record) => record.isApproved.indexOf(input) > -1,
                // render: e => <span>{e == null ? "N/P" : (e ? "Yes" : "No")}</span>,
                render: e => <span>{e ? <Tag color="#87d068">Exported</Tag> : <Tag color="">Pending</Tag>}</span>,
            },
            {
                title: 'Is Requested?',
                dataIndex: 'isRequested',
                key: 'isRequested',

                render: (value, record) => {
                    const isRequested = record.productionRequestId;
                    return isRequested ? 
                    <div style={{width: 100}}>
                        {dayjs(record.productionRequest?.requestAt).format("hh:mm A")} to {dayjs(record.productionRequest?.requestEndsAt).format("hh:mm A")}
                    </div>
                     : "No";
                }
            },
            {
                title: 'Action',
                key: 'operation',
                // fixed: 'right',
                render: (record) => {
                    if (record.exportedAt) return;
                    return (
                        <Space size="small" wrap={true}>
                            <Button size="small" type="primary" danger onClick={() => this.handleDelete(record)}>
                                Delete
                            </Button>
                            <Button size="small" type="default" onClick={() => this.handleCopy(record)}>
                                Copy
                            </Button>
                            {record?.producedEndsAt ? 
                                <Button size="small" type="primary" onClick={() => window.open(`#/time-records/${record.key}/edit`)}>
                                    Edit (New)
                                </Button> :
                                <Button size="small" type="primary" onClick={() => window.open(`#/jobs/time-records/${record.key}`)}>
                                    Edit
                                </Button>
                            }
                        </Space >

                    )
                },
            },
        ];

        const timeRecordComponent = {
            body: {
                row: EditableTimeRecordRow,
                cell: EditableTimeRecordCell,
            },
        };

        timeColumns = timeColumns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });

        let productionColumns = [
            {
                title: 'Cost Code',
                dataIndex: 'costCode',
                key: 'costCode',
                width: '10%',
                editable: true,
                ...this.getColumnSearchProps('costCode'),
            },
            {
                title: 'Cost Code Description',
                dataIndex: 'costCodeDescription',
                key: 'costCodeDescription',
                ...this.getColumnSearchProps('costCode'),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Production',
                dataIndex: 'quantity',
                key: 'quantity',
                editable: true,
                width: '30%',
            },
            {
                title: 'Unit of Measure',
                dataIndex: 'unit',
                key: 'unit',
                width: '20%',
                ...this.getColumnSearchProps('unit'),
            },
            {
                title: 'Date',
                dataIndex: 'producedAt',
                key: 'producedAt',
                editable: true,
                render: producedAt => <>{moment(producedAt).format("YYYY-MM-DD")}</>,
            },
            {
                title: 'Notes',
                dataIndex: 'description',
                key: 'description',
                editable: true,
                ...this.getColumnSearchProps('description'),
            },
            {
                title: 'Action',
                key: 'operation',
                // fixed: 'right',
                render: (record) => {
                    const self = this;

                    return (
                        <Space size="small" wrap={true}>
                            <Button size="small" type="primary" danger onClick={() => this.handleDelete(record)}>
                                Delete
                            </Button>
                            <Button size="small" type="primary" onClick={() => window.open(`#/jobs/productions/${record.key}`)}>
                                Edit
                            </Button>
                        </Space >

                    )
                },
            },
        ];

        let equipmentColumns = [
            {
                title: 'Equipment',
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
                editable: true,
                width: "40%",
            },
            {
                title: 'Hours Used',
                dataIndex: 'hoursUsed',
                key: 'hoursUsed',
                editable: true,
            },
            {
                title: 'Liters of Fuel',
                dataIndex: 'litersOfFuel',
                key: 'litersOfFuel',
                editable: true,
            },
            {
                title: 'Notes',
                dataIndex: 'notes',
                key: 'notes',
                editable: true,
            }
        ]

        equipmentColumns = equipmentColumns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: record.production?.exportedAt ? false : col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });


        productionColumns = productionColumns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });


        return (
            <ConfigProvider5 prefixCls="ant5">
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Reports</Breadcrumb.Item>
                    <Breadcrumb.Item>Foreman Report</Breadcrumb.Item>
                    <Breadcrumb.Item>{this.state.job.name}</Breadcrumb.Item>
                </Breadcrumb>

                <Flex style={{ marginBottom: 16 }}>
                    <Button 
                        type="primary" 
                        iconPosition="start" 
                        icon={<LeftOutlined />}
                        href={`#/reports/foreman-report/${this.state.jobId}?date=${dayjs(new URLSearchParams(this.props.location.search).get("date")).add(-1, "day").toISOString()}`}
                    >
                        {
                            dayjs(new URLSearchParams(this.props.location.search).get("date")).add(-1, "day").format("YYYY-MM-DD")
                        }
                    </Button>
                    
                    <Button type="secondary" iconPosition="start" icon={<CalendarOutlined />}>
                        {
                            dayjs(new URLSearchParams(this.props.location.search).get("date")).format("YYYY-MM-DD")
                        }
                    </Button>
                    
                    <Button 
                        type="primary"  
                        iconPosition="end" 
                        style={{ marginLeft: 4 }} 
                        icon={<RightOutlined />}
                        href={`#/reports/foreman-report/${this.state.jobId}?date=${dayjs(new URLSearchParams(this.props.location.search).get("date")).add(1, "day").toISOString()}`}
                    >
                        {
                            dayjs(new URLSearchParams(this.props.location.search).get("date")).add(1, "day").format("YYYY-MM-DD")
                        }
                    </Button>
                
                </Flex>

                {this.state.isMissingTimecards ? <Alert
                    message="Missing Time Cards"
                    description={`Please remind workers to submit time cards.`}
                    type="error"
                    banner
                />
                :
                this.state.production?.length ?
                        <>
                            <Card title="Time Records">
                                <Table
                                    components={timeRecordComponent}
                                    rowClassName={(record) => {
                                        console.log("ROW CLASS NAME START")
                                        const classNames = [];

                                        if (record.exportedAt) {
                                            classNames.push("disabled-row")
                                        }
                                        else {
                                            classNames.push("editable-row")
                                        }

                                        if (record.productionRequestId && record.productionRequest) {
                                            const request = record.productionRequest;
                                            const requestStart = dayjs(request.requestAt);
                                            const requestEnd = dayjs(request.requestEndsAt);
                                            const recordStart = dayjs(record.producedAt);
                                            const recordEnd = dayjs(record.producedEndsAt);
                                            console.log({ requestStart: request.requestAt, requestEnd: request.requestEndsAt, prodStart: record.producedAt, prodEnd: record.producedEndsAt })
                                            const isOutOfHours = recordStart.isBefore(requestStart) || recordEnd.isAfter(requestEnd);
                                            if (isOutOfHours) classNames.push("row-bg-error");
                                        }
                                        
                                        if (!record.productionRequestId)
                                        {
                                            classNames.push("row-bg-warn");
                                        }

                                        console.log(classNames);
                                        return classNames.join(" ");
                                    }}
                                    onChange={() => {
                                        console.log("Changed")
                                    }}
                                    rowKey={record => `production-${record.key} `}
                                    columns={timeColumns}
                                    expandable={{
                                        expandedRowRender: record => {
                                            return (<>
                                                <Table
                                                    components={timeRecordComponent}
                                                    rowClassName={(record) => 'editable-row'}
                                                    rowKey={record => `equipment-${record.id} `}
                                                    dataSource={record.productionEquipments?.map(x => { return { key: x.id, exportedAt: record.exportedAt, name: x.equipment.name, ...x, isProductionEquipment: true } })}
                                                    columns={equipmentColumns}>
                                                </Table>
                                            </>)
                                        },
                                        rowExpandable: record => record.productionEquipments?.length > 0
                                    }}
                                    dataSource={this.state.timeRecords}
                                    filtered
                                    scroll={{ x: 1300 }}
                                    pagination={false}
                                    footer={currentPageData => {
                                        let data = this.state.timeRecords;

                                        function handleMenuClick(e) {
                                            if (e.key.includes("filtered")) {
                                                data = currentPageData;
                                            }

                                            let fileType = exportFromJSON.types.txt;

                                            switch (e.key) {
                                                case "csv":
                                                    fileType = exportFromJSON.types.csv;
                                                    break;
                                                case "xls":
                                                    fileType = exportFromJSON.types.xls;
                                                    break;
                                                case "json":
                                                    fileType = exportFromJSON.types.json;
                                                    break;
                                                case "txt":
                                                    fileType = exportFromJSON.types.txt;
                                                    break;
                                                case "filtered-csv":
                                                    fileType = exportFromJSON.types.csv;
                                                    break;
                                                case "filtered-xls":
                                                    fileType = exportFromJSON.types.xls;
                                                    break;
                                                case "filtered-json":
                                                    fileType = exportFromJSON.types.json;
                                                    break;
                                                case "filtered-txt":
                                                    fileType = exportFromJSON.types.txt;
                                                    break;
                                            }

                                            const getOptionName = (value) => {
                                                let name = "N/P";
                                                if (value == null) name = "N/P";
                                                if (value == true) name = "Approved";
                                                if (value == false) name = "Declined";
                                                return name;
                                            }

                                            // let mappedTimeRecords = timeRecords.map(x => {
                                            //     return {
                                            //         job: x.job?.name,
                                            //         key: x.id,
                                            //         costCode: x.costCode.name,
                                            //         producedAt: x.producedAt,
                                            //         quantity: x.quantity,
                                            //         description: x.description,
                                            //         workType: x.workType?.name,
                                            //         workTypeObj: x.workType,
                                            //         costCodeId: x.costCodeId,
                                            //         isApproved: x.isApproved,
                                            //         productionEquipments: x.productionEquipments,
                                            //         type: x.type,
                                            //         name: x.user?.name,
                                            //         user: x.user,
                                            //         costCodeObj: x.costCode,
                                            //         jobObj: x.job,
                                            //     }
                                            // })

                                            const output = data.map(x => {
                                                return {
                                                    Approved: getOptionName(x.isApproved),
                                                    Date: moment(x.producedAt).format("YYYY-MM-DD"),
                                                    "Cost Code": `${x.costCodeObj.name} - ${x.costCodeObj.description}`,
                                                    Name: x.name,
                                                    Hours: x.quantity,
                                                    Description: x.description,
                                                    Equipment: x.productionEquipments?.map(x => x.equipment.name).join('|'),
                                                    EquipmentHours: x.productionEquipments?.map(x => x.hoursUsed).join('|'),
                                                    EquipmentFuel: x.productionEquipments?.map(x => x.litersOfFuel).join('|'),
                                                    EquipmentNotes: x.productionEquipments?.map(x => `"${x.notes}"`).join('|')
                                                }
                                            })

                                            exportFromJSON({
                                                data: output,
                                                fileName: "foreman-time-report",
                                                exportType: fileType
                                            });
                                        }

                                        const menu = (
                                            <Menu onClick={handleMenuClick}>
                                                <Menu.Item key="csv">Export as CSV</Menu.Item>
                                                <Menu.Item key="xls">Export as XLS</Menu.Item>
                                                <Menu.Item key="json">Export as JSON</Menu.Item>
                                            </Menu>
                                        );

                                        const filteredMenu = (
                                            <Menu onClick={handleMenuClick}>
                                                <Menu.Item key="filtered-csv">Export as CSV</Menu.Item>
                                                <Menu.Item key="filtered-xls">Export as XLS</Menu.Item>
                                                <Menu.Item key="filtered-json">Export as JSON</Menu.Item>
                                            </Menu>
                                        );

                                        return (
                                            <Space size="small">
                                                <Dropdown overlay={menu}>
                                                    <Button type="primary" ghost={true}>Download All</Button>
                                                </Dropdown>
                                                <Dropdown overlay={filteredMenu}>
                                                    <Button type="primary" ghost={true}>Download Filtered</Button>
                                                </Dropdown>
                                            </Space>
                                        );

                                    }}
                                />
                            </Card>

                            <Card title="Production">

                                <Table
                                    components={timeRecordComponent}
                                    rowClassName={() => 'editable-row'}
                                    rowKey={record => `production - ${record.key} `}
                                    columns={productionColumns}
                                    dataSource={this.state.productionRecords}
                                    scroll={{ x: 1300 }}
                                    pagination={false}
                                    footer={currentPageData => {

                                        let data = this.state.productionRecords;

                                        function handleMenuClick(e) {
                                            if (e.key.includes("filtered")) {
                                                data = currentPageData;
                                            }

                                            let fileType = exportFromJSON.types.txt;

                                            switch (e.key) {
                                                case "csv":
                                                    fileType = exportFromJSON.types.csv;
                                                    break;
                                                case "xls":
                                                    fileType = exportFromJSON.types.xls;
                                                    break;
                                                case "json":
                                                    fileType = exportFromJSON.types.json;
                                                    break;
                                                case "txt":
                                                    fileType = exportFromJSON.types.txt;
                                                    break;
                                                case "filtered-csv":
                                                    fileType = exportFromJSON.types.csv;
                                                    break;
                                                case "filtered-xls":
                                                    fileType = exportFromJSON.types.xls;
                                                    break;
                                                case "filtered-json":
                                                    fileType = exportFromJSON.types.json;
                                                    break;
                                                case "filtered-txt":
                                                    fileType = exportFromJSON.types.txt;
                                                    break;
                                            }

                                            exportFromJSON({
                                                data: data.map(({ job, key, ...items }) => items),
                                                fileName: "foreman-production-report",
                                                exportType: fileType
                                            });
                                        }

                                        const menu = (
                                            <Menu onClick={handleMenuClick}>
                                                <Menu.Item key="csv">Export as CSV</Menu.Item>
                                                <Menu.Item key="xls">Export as XLS</Menu.Item>
                                                <Menu.Item key="json">Export as JSON</Menu.Item>
                                            </Menu>
                                        );

                                        const filteredMenu = (
                                            <Menu onClick={handleMenuClick}>
                                                <Menu.Item key="filtered-csv">Export as CSV</Menu.Item>
                                                <Menu.Item key="filtered-xls">Export as XLS</Menu.Item>
                                                <Menu.Item key="filtered-json">Export as JSON</Menu.Item>
                                            </Menu>
                                        );

                                        return (
                                            <Space size="small">
                                                <Dropdown overlay={menu}>
                                                    <Button type="primary" ghost={true}>Download All</Button>
                                                </Dropdown>
                                                <Dropdown overlay={filteredMenu}>
                                                    <Button type="primary" ghost={true}>Download Filtered</Button>
                                                </Dropdown>
                                            </Space>
                                        );

                                    }}
                                />
                            </Card>
                        </>
                        :
                        <Card>
                        <Result
                                title="No Records Found"
                                extra={
                                    <Button type="primary" href="#/reports/foreman-report">
                                        Go Back
                                    </Button>
                                }
                                style={{ opacity: this.state.job?.name && this.state.production?.length > 0 ? 100 : 100 }}
                            />
                        </Card>}


            </ConfigProvider5>
        );
    }
}